import { generateRandomNumber } from "./../utils/helpers.utils";
const ExpressConstants = {
  EXPRESS_CONSTANTS: {
    CONSIGNMENTS_DATA: {
      TABLE_WRAPPER_DATA: {
        title: "Consignments",
        HeaderData: [
          {
            field: "id",
            headerName: "Id",
            hide: true,
          },
          {
            field: "hubCode",
            headerName: "Hub",
            width: 100,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hub",
              fieldName: "hub",
              fieldFullName: "hub",
              multiple: true,
              key: `autoComplete-hub-${generateRandomNumber(0, 1000)}`,
              label: "Hub Code",
              items: [],
            },
          },
          {
            field: "hubNames",
            headerName: "Hub Name",
            width: 180,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "hubName",
              fieldName: "hubName",
              fieldFullName: "hubName",
              multiple: true,
              key: `autoComplete-hubName-${generateRandomNumber(0, 1000)}`,
              label: "Hub Name",
              items: [],
            },
          },
          {
            field: "consignmentId",
            headerName: "Consignment ID",
            width: 130,
          },
          {
            field: "status",
            headerName: "Order Status",
            width: 186,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "status",
              fieldName: "status",
              fieldFullName: "status",
              multiple: true,
              key: `autoComplete-status-${generateRandomNumber(0, 1000)}`,
              label: "Order Status",
              items: [],
            },
          },
          {
            field: "vehicle",
            headerName: "Vehicle",
            width: 80,
          },
          {
            field: "promiseTime",
            headerName: "Delivery Promise",
            width: 100,
          },
          {
            field: "deliveryStatus",
            headerName: "Status",
            width: 186,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryStatus",
              fieldName: "deliveryStatus",
              fieldFullName: "deliveryStatus",
              multiple: true,
              key: `autoComplete-deliveryStatus-${generateRandomNumber(
                0,
                1000
              )}`,
              label: "Hub Name",
              items: [],
            },
          },
          {
            field: "activity",
            headerName: "Activity Type",
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "activity",
              fieldName: "activity",
              fieldFullName: "activity",
              multiple: true,
              key: `autoComplete-activity-${generateRandomNumber(0, 1000)}`,
              label: "activity",
              items: [],
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            width: 100,
            hide: false,
          },
          {
            field: "driverName",
            headerName: "Driver",
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "driverId",
              fieldName: "driverId",
              fieldFullName: "driverId",
              multiple: true,
              key: `autoComplete-driverId-${generateRandomNumber(0, 1000)}`,
              label: "Driver Name",
              items: [],
            },
          },
          {
            field: "deliveryArea",
            headerName: "Customer Area",
            width: 180,
            tooltip: true,
            tooltipDataKey: "address",
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryArea",
              fieldName: "deliveryArea",
              fieldFullName: "deliveryArea",
              multiple: true,
              key: `autoComplete-deliveryArea-${generateRandomNumber(0, 1000)}`,
              label: "Area",
              category: "ExpressArea",
              items: [],
            },
          },
          {
            field: "addressStatus",
            headerName: "Address Status",
            width: 160,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "addressStatus",
              fieldName: "addressStatus",
              fieldFullName: "c.deliveryInformation.customerContactInfo.address.status",
              multiple: true,
              key: `autoComplete-addressStatus-${generateRandomNumber(0, 1000)}`,
              label: "Area",
              items: [],
            },
          },
          {
            field: "deliveryZone", 
            headerName: "Zone",
            width: 150,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryZone",
              fieldName: "deliveryZone",
              fieldFullName: "c.metadata.deliveryZone",
              multiple: true,
              key: `autoComplete-deliveryZone-${generateRandomNumber(0, 1000)}`,
              label: "Zone",
              items: []
            }
          },
          {
            field: "deliverySlotTime",
            headerName: "Delivery Time",
            width: 180,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldName: "deliverySlotTime",
              multiple: true,
              key: `autoComplete-deliverySlot-${generateRandomNumber(0, 1000)}`,
              label: "Delivery Slot",
              items: [],
              fieldKey: "deliverySlotTime",
            },
          },
          
          {
            type: "date",
            field: "deliveryDate",
            headerName: "Delivery Date",
            width: 160,
            filter: true,
            filterObj: {
              type: "dateRange",
              fieldKey: "deliveryDate",
              fieldName: "dateRange",
              key: `dateRange-filter-${generateRandomNumber(0, 1000)}`,
              placeholderText: "Delivery Date",
              label: "Delivery Date",
              autoUpdateInput: false,
              autoApply: true,
              showDropdowns: true,
              linkedCalendars: true,
              maxSpan: {
                month: "1",
              },
              locale: {},
            },
          },
          {
            field: "paymentType",
            headerName: "Payment Type",
            width: 160,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "paymentType",
              fieldName: "paymentType",
              fieldFullName: "paymentType",
              multiple: true,
              key: `autoComplete-paymentType-${generateRandomNumber(0, 1000)}`,
              label: "Payment Type",
              items: [],
            },
          },

          {
            field: "orderId",
            headerName: "Order ID",
            width: 150,
          },
          {
            field: "transactionDate",
            headerName: "Order Creation Date",
            width: 170,
          },
          {
            field: "deliveryActualTime",
            headerName: "Delivery Actual Time(mins)",
            width: 180,
          },
          {
            field: "deliveryExecutionStatus",
            headerName: "Delivery Status",
            width: 180,
            filter: true,
            filterObj: {
              type: "autocomplete",
              fieldKey: "deliveryExecutionStatus",
              fieldName: "deliveryExecutionStatus",
              fieldFullName: "deliveryExecutionStatus",
              multiple: true,
              key: `autoComplete-deliveryExecutionStatus-${generateRandomNumber(
                0,
                1000
              )}`,
              label: "Delivery Status",
              items: [],
            },
          },
          {
            field: "customerName",
            headerName: "Customer Name",
            width: 150,
          },
          {
            field: "customerEmail",
            headerName: "Customer Email",
            width: 180,
          },
          {
            field: "totalAmount",
            headerName: "Total Order Value",
            width: 140,
          },
          {
            field: "codAmount",
            headerName: "COD Amount",
            width: 130,
          },
          {
            field: "collectedAmount",
            headerName: "Collected Amount",
            width: 160,
          },
          {
            field: "undeliveredReason",
            headerName: "Undelivered Reason",
            width: 160,
          },
        ],
        headerButtons: [
          {
            name: "colsettings",
            displayName: "Column Settings",
            disabled: false,
            childElements: [
              {
                name: "excel",
                displayName: "EXPORT DATA",
                disabled: false,
              },
            ],
          },
        ],
        loadMore: {
          name: "loadMore",
          disabled: false,
          pageSize: 20,
        },
        defaultFiltersObj: {
          hub: {
            key: "hub",
            value: [],
          },
          hubName: {
            key: "hubNames",
            value: [],
          },
          deliveryArea: {
            key: "deliveryArea",
            value: [],
          },
          addressStatus: {
            key: "addressStatus",
            value: [],
          },
          activity: {
            key: "activity",
            value: [],
          },
          deliveryZone: {
            key: "deliveryZone",
            value: [],
          },
          deliveryStatus: {
            key: "deliveryStatus",
            value: [],
          },
          status: {
            key: "status",
            value: [],
          },
          paymentType: {
            key: "paymentType",
            value: [],
          },
          deliveryExecutionStatus: {
            key: "deliveryExecutionStatus",
            value: [],
          },
          driverId: {
            key: "driverId",
            value: [],
          },
          deliverySlotTime: {
            key: "deliverySlotTime",
            value: [],
          },
          pageNumber: 0,
          dateRange: {
            key: "dateRange",
            value: {
              startDate: new Date(),
              endDate: new Date(),
            },
          },
        },
        SEARCH_TYPES: [
          {
            name: "Consignment Id",
            value: "consignmentCode",
          },
          {
            name: "Order Id",
            value: "orderCode",
          },
          {
            name: "Customer Name",
            value: "customerName",
          },
          {
            name: "Customer Email",
            value: "customerEmail",
          },
        ],
        sortModel: {
          field: "deliveryDate",
          direction: "asc",
        },
      },
      FILTER_CHIPS: [
        {
          code: "UNASSIGNED",
          value: "Unassigned",
        },
        {
          code: "ON_HOLD",
          value: "On Hold",
        },
        {
          code: "ASSIGNED",
          value: "Assigned",
        },
        {
          code: "OUT_FOR_DELIVERY",
          value: "Out For Delivery",
        },
        {
          code: "COMPLETED",
          value: "Completed",
        },
        {
          code: "ALL",
          value: "All",
        },
      ],
      STATUSES_ALECTO_CRATE:['UNASSIGNED', 'ON_HOLD', 'ASSIGNED', 'READY_TO_DISPATCH','OUT_FOR_DELIVERY'],
      STATUSES: {
        UNASSIGNED: {
          color: "INFO_BLUE",
        },
        ON_HOLD: {
          color: "MINSK",
        },
        ASSIGNED: {
          color: "MINSK",
        },
        READY_TO_DISPATCH: {
          color: "READY_BLUE",
        },
        OUT_FOR_DELIVERY: {
          color: "PRIMARY_BLUE",
        },
        DELIVERED: {
          color: "JUNGLE_GREEN",
        },
        PARTIALLY_DELIVERED: {
          color: "PARTIAL_SUCCESS_GREEN",
        },
        DELIVERY_ATTEMPTED: {
          color: "ALERT_ORANGE",
        },
        CANCELLED_BY_SOURCE: {
          color: "PALE_GRAY",
        },
        DELIVERY_FAILED: {
          color: "ERROR_RED",
        },
        PACKING_COMPLETE: {
          color: "MINSK",
        },
      },
      DRIVER_STATUSES: {
        ACTIVE: {
          color: "GREY_SCALE",
          hintText: "",
          value: "ACTIVE",
        },
        AVAILABLE: {
          color: "SEA_GREEN",
          hintText: "",
          value: "AVAILABLE",
        },
        UNASSIGNED: {
          color: "SEA_GREEN",
          hintText: "",
          value: "UNASSIGNED",
        },
        ASSIGNED: {
          color: "GOLDEN_BELL",
          hintText: "",
          value: "ASSIGNED",
        },
        BACK_TO_STORE: {
          color: "INFO_BLUE",
          hintText: "",
          value: "BACK_TO_STORE",
        },
        AWAY: {
          color: "GREY_SCALE",
          hintText: "",
          value: "AWAY",
        },
        OUT_FOR_DELIVERY: {
          color: "GREY_SCALE",
          hintText: "",
          value: "OUT_FOR_DELIVERY",
        },
        OFFLINE: {
          color: "GREY_SCALE",
          hintText: "",
          value: "OFFLINE",
        },
      },
      ORDER_STATUSES: {
        READY: {
          color: "INFO_BLUE",
        },
        EXPORTED: {
          color: "READY_BLUE",
        },
        READY_TO_DISPATCH: {
          color: "READY_BLUE",
        },
        PAYMENT_ACCEPTED: {
          color: "MINSK_DARK",
        },
        DISPATCHED: {
          color: "SCAMPI",
        },
        DELIVERY_ATTEMPTED: {
          color: "DRIFT_WOOD",
        },
        OUT_FOR_DELIVERY: {
          color: "TAG_YEllOW",
        },
        DELIVERED: {
          color: "PERSIAN_GREEN_DARK",
        },
        PARTIALLY_DELIVERED: {
          color: "PARTIAL_SUCCESS_GREEN",
        },
        CANCELLED: {
          color: "SILVER_CHALICE2",
        },
        DELIVERY_FAILED: {
          color: "PALE_RED",
        },
        PACKING_COMPLETE: {
          color: "GREEN_BLUE",
        },
        PICKING_STARTED: {
          color: "MAROON",
        },
      },
      ORDER_STATUS: {
        READY: "READY",
        EXPORTED: "EXPORTED",
        READY_TO_DISPATCH: "READY_TO_DISPATCH",
        PAYMENT_ACCEPTED: "PAYMENT_ACCEPTED",
        DISPATCHED: "DISPATCHED",
        DELIVERY_ATTEMPTED: "DELIVERY_ATTEMPTED",
        OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
        DELIVERED: "DELIVERED",
        PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
        CANCELLED: "CANCELLED",
        DELIVERY_FAILED: "DELIVERY_FAILED",
        PACKING_COMPLETE: "PACKING_COMPLETE",
        PICKING_STARTED: "PICKING_STARTED",
      },
      DELIVERY_EXECUTION_STATUS: {
        DELAYED:{
          color: "ORANGE",
        },
        ON_TIME:{
          color: "SUCCESS_GREEN",
        },
      },
      TAB_VALUES: {
        ORDER_DETAIL: "Order Detail",
        ORDER_LOG: "Order",
        ORDER_COMMENTS: "Order Comments"
      },
      DEFAULT_FILTER_CHIP: "UNASSIGNED",
      ALL_FILTER_CHIP: "ALL",
      NON_EDITABLE_STATUSES: [
        "DELIVERY_FAILED",
        "CANCELLED_BY_SOURCE",
        "PARTIALLY_DELIVERED",
        "DELIVERED",
      ],
      REFRESHABLE_STATUSES:['READY','EXPORTED','PICKING_STARTED'],
      DRIVER_ASSIGNED_SUCCESS_MSG: "Driver Assigned successfully",
      DEFAULT_STATUS_SELECTION: "DELIVERED",
      PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
      REPLANNING_CONSIGNMENT_SUCCESS_MSG: "Consignment updated successfully.",
      ADDRESS_MARKED_UNVERIFIED: "Address has been marked as inaccurate",
    },
    DELIVERY_TYPE: {
      DISPATCHER: "DELIVERY_DISPATCHER",
      LINE_ITEM_REJECTION: "LINE_ITEM_REJECTION",
      ON_HOLD: "ONHOLD_DISPATCHER",
      ORDER_COMMENTS: "ORDER_COMMENTS"
    },
    DRIVERS_CATEGORY: {
      ASSIGNED: "ASSIGNED",
      UNASSIGNED: "UNASSIGNED",
      OTHERS: "OTHERS",
    },
    DRIVER_STATUS: {
      OUT_FOR_DELIVERY: "Out For Delivery",
    },
    VEHICLE_TYPE: {
      CAR: "CAR",
      BIKE: "BIKE",
    },
    RESPONSE_CONSTANTS: {
      ERROR_CODES: {
        SESSION_TIMEOUT: "UM100",
        UNAUTHORISED: "UM101",
        CONCURRENT_CONSIGNMENT: "CWO15",
      },
    },
    ORDER_LOG_MODIFIED_BY: {
      MANUAL: "Manual",
      SYSTEM: "System",
    },
    VALIDTION_ADDITIONAL_COMMENT:{
      REASON: {
        REQUIRED: {
          msg: "Please select the reason"
        }
      },
      COMMENT: {
        REQUIRED: {
          msg: "Additional information is required"
        }
      },
    }
  },
};

export default ExpressConstants;

export enum EXPRESS_DELIVERY_TYPE {
    UNASSIGNED = "UNASSIGNED",
    ASSIGNED = "ASSIGNED",
    ON_HOLD = "ON_HOLD",
    DELIVERY_ATTEMPTED = "DELIVERY_ATTEMPTED",
    UNDELIVERED = "UNDELIVERED",
}