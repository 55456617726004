import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router";

import moment from "moment";
import _ from "lodash";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { TabContext, TabPanel, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@material-ui/lab";

import { AppState } from "../config/redux/reducers";

import { StepperTimelineConnector, useStyles } from "./CreateRoute.styles";
import { useStyles as useStyleNew } from "./AllRoutes/AllRoutes.styles";

import { ReactComponent as searchIcon } from "./../assets/icons/search-icon-small.svg";
import { ReactComponent as refreshIcon } from "./../assets/icons/refresh-icon.svg";
import { ReactComponent as consignmentsIcon } from "./../assets/icons/consignments-icon.svg";
import { ReactComponent as activeTimelineDot } from "./../assets/icons/active-timeline-dot.svg";
import { ReactComponent as mapViewIcon } from "./../assets/icons/map-view-icon.svg";
import { ReactComponent as listViewIcon } from "./../assets/icons/list-view-icon.svg";
import { ReactComponent as areaTimelineIcon } from "./../assets/icons/timeline-area-icon.svg";
import { ReactComponent as infoWarningIcon } from "./../assets/icons/info-warning-icon.svg";
import { ReactComponent as cratesIcon } from "./../assets/icons/crates-icon.svg";
import { ReactComponent as lockIcon } from "./../assets/icons/lock-icon.svg";
import { ReactComponent as walletIcon } from "./../assets/icons/wallet-icon.svg";
import { ReactComponent as mobileIcon } from "./../assets/icons/mobile-icon.svg";
import { ReactComponent as locationIcon } from "./../assets/icons/location-icon.svg";
import { ReactComponent as downArrowIcon } from "./../assets/icons/down-arrow-icon.svg";
import { ReactComponent as infoErrorIcon } from "./../assets/icons/info-error-icon.svg";
import { ReactComponent as timeSlotIcon } from "./../assets/icons/time-slot-icon.svg";
import { ReactComponent as editIcon } from "./../assets/icons/edit-icon.svg";
import transferPointIcon from "./../assets/icons/transfer-point-icon.svg";
import mapHubIcon from "./../assets/icons/timeline-area-icon.svg";
import mapItemIcon from "./../assets/icons/map-item-icon.svg";
import alertIcon from "./../assets/icons/Alert.svg";
import calendarIcon from "./../assets/icons/calendar-icon.svg";
import verifiedIcon from "./../assets/icons/verified-icon.svg";
import unverifiedIcon from "./../assets/icons/unverified-icon.svg";
import pendingIcon from "./../assets/icons/pending-icon.svg";
import TrcukFilledIcon from "./../assets/icons/TrcukFilledIcon.svg";

import AppConstants from "../constants/";
import Loader from "../common/Loader";
import DateRangePickerComponent from "../common/DateRangeComponent";
import CustomSnackbar from "../common/CustomSnackbar";
import TextBox from "../common/TextBox";
import CustomDialog from "../common/Dialog";
import ConsignmentDetails from "./ConsignmentDetails";
import MapContainer from "common/GoogleMapContainer";
import CustomTooltip from "common/Tooltip";
import CustomPlannedRouteView from "./AllRoutes/common/CustomPlannedRouteView";

import SelectBox, { SelectItemProps } from "../common/SelectBox";
import { userLogout } from "../Login/redux/loginSlice";
import { API_RESPONSE_STATUS } from "../constants/CommonConstants";
import { COLORS } from "config/theme/baseTheme";
import { ADDRESS_STATUS } from "../constants/CommonConstants";
import { mapOptionStyles, ROUTE_TYPE, ALL_ROUTES_TEXT_CONTANT, ALL_ROUTE_INITIAL_STATE, ALL_ROUTE_INITIAL_STATE_INTERFACE } from "./AllRoutes/utils/AllRouteConstant";
import { CreateRouteConstant } from "./CreateRoute/CreateRouteConstant";
import {
  calculateTransitTime,
  createDraftRoutePayload,
  createGetAllDropdownsPayload,
  createGetAllPlannedRoutesPayload,
  createRouteCreationPayload,
  createUpdateOrderStatusPayload,
  getAllAreasAndSlots,
  getConsignmentData,
  getTotalCrates,
  transformRouteConsignments,
  getAddressInfo,
  getRouteStatusField,
  getConsignmentStatusField,
  checkTerminalStatus,
  createPayloadToReleaseAssignee,
  createPayloadToMarkAddressUnverified,
  isRouteStatusOutForDelivery,
  transformedDataViewRoute,
} from "../mocks/routePlanning/response.transforms";
import {
  createDraftRoute,
  createAndEditRoute,
  fetchAllDrivers,
  fetchAllHelpers,
  fetchAllVehicles,
  fetchAllPlannedRoutes,
  fetchAllPlannedRoutesNew,
  updateConsignmentStatus,
  discardRouteChanges,
  resetPlanningError,
  updateAllConsignmentsState,
  loadAllSlots,
  clearCurrentSlot,
  fetchRouteConsignments,
  clearUpdatedRoute,
  releaseAssignee,
  getAddressStatus,
  resetAddressupdateStatus,
  getDriverMarkerApi,
  fetchSubRouteById,
  resetViewRouteState,
  fetchOrderById,
  resetRouteConsignmentsState,
  resetSubrouteState,
} from "./redux/planningSlice";
import {
  getMatchingValues,
  getSlotUIValue,
  getUniqueValues,
  highlightTextWithClass,
  isEqualArrays,
  convertTime,
  getDifferenceValues,
  isUndefined,
  isNull,
  isNonEmptyObject,
  isObject,
  countObjectProperties,
  loadDashIsEmpty,
} from "../utils/helpers.utils";
import {
  designAllRoutesStateObjectUtils,
  handleRouteAccordianClickedUtils,
  handleRoutAreaSubareaAccordianToggleUtils,
  handleRoutAreaAccordianToggleUtils,
  handleRouteSlotAccordianToggleUtils,
  handleSubRouteSlotAccordianToggleUtils,
  handleSubrouteOrderAccordianToggleUtils,
  handleOrderAccordianToggleUtils,
} from "./AllRoutes/utils/AllRouteUtils";
import RenderRescheduleOrders from "../common/RescheduleOrders/RenderRescheduleOrders";

interface CreateRouteProps {
  location?: any;
  match?: any;
  editMode?: boolean;
  viewMode?: boolean;
}

const CreateRoute = (props: CreateRouteProps) => {
  const { editMode, viewMode, match, location } = props;

  const classes2 = useStyleNew();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { mapRouteDirections, countryCode, hubData } = useSelector((state: AppState) => state.common);
  const { isLastMileViewer, isOnlyStdLastMileViewer } = useSelector((state: AppState) => state.userLogin);
  const {
    loading,
    draftRoute,
    areawiseConsignments,
    slotwiseConsignments,
    allConsignments,
    routeConsignments,
    driversList,
    helpersList,
    vehiclesList,
    vehiclesData,
    routeCreateSuccess,
    updatedRoute,
    routeDiscardSuccess,
    currentSlot,
    slotsList,
    zoneList,
    consignmentStatusList,
    error,
    errorCode,
    isReleaseAssignee,
    releaseMessage,
    consignmentDataAfterAddressUpdate,
    isAddressUpdated,
    subRoutes,
    order,
    plannedRoutesNew,
    fetchStatus,
  } = useSelector((state: AppState) => state.routePlanning);

  const shouldBlockNavigationRef = useRef<boolean>(false);
  const autoRefreshMapRef = useRef<boolean>(false);
  const hubNameRef = useRef<string>(location.state && location.state.hubName);
  const onLoadRef = useRef<boolean>(false);
  const filterChangeRef = useRef<boolean>(false);
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");

  const [allRouteState, setAllRouteState] = useState<ALL_ROUTE_INITIAL_STATE_INTERFACE>(ALL_ROUTE_INITIAL_STATE);
  const [createRouteState, setCreateRouteState] = useState<any>({ countDownInterval: 0, minutes: null, seconds: null, refreshInterval: 0 });
  const [mapCenterState, setMapCenterState] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [tabValue, setTabValue] = useState<any>(location.state && location.state.tabValue ? location.state.tabValue : AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED);
  const [autoRefreshMap, setAutoRefreshMap] = useState(false);
  const [optimizeMapRoute, setOptimizeMapRoute] = useState(false);
  const [updateMap, setUpdateMap] = useState(false);
  const [editRoute, setEditRoute] = useState<any>(null);
  const [noRedirect, setNoRedirect] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [draggingItem, setDraggingItem] = useState(false);
  const [toggleAllDropdownExpanded, setToggleAllDropdownExpanded] = useState(false);
  const [mapMarkers, setMapMarkers] = useState<any>([]);
  const [consignmentsSequence, setConsignmentsSequence] = useState<any>({});
  const [lastSequenceNumber, setLastSequenceNumber] = useState(0);
  const [openDiscardConfirmDialog, setOpenDiscardConfirmDialog] = useState(false);
  const [openDateChangeConfirmDialog, setOpenDateChangeConfirmDialog] = useState(false);
  const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] = useState(false);
  const [openCapacityBreachConfirmDialog, setOpenCapacityBreachConfirmDialog] = useState(false);
  const [capacityBreachPopupMsg, setCapacityBreachPopupMsg] = useState("");
  const [openConsignmentDetailsPopup, setOpenConsignmentDetailsPopup] = useState(false);
  const [consignmentData, setConsignmentData] = useState({});
  const [consignmentExpanded, setConsignmentExpanded] = useState<string[]>([]);
  const [searchedConsignmentExpanded, setSearchedConsignmentExpanded] = useState<string[]>([]);
  const [consignmentSearchValue, setConsignmentSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState<string[]>([]);
  const [slotValue, setSlotValue] = useState<string[]>([]);
  const [zoneValue, setZoneValue] = useState<string[]>([]);
  const [driverValue, setDriverValue] = useState(AppConstants.SELECT_NONE.value);
  const [helperValue, setHelperValue] = useState(AppConstants.SELECT_NONE.value);
  const [vehicleValue, setVehicleValue] = useState(AppConstants.SELECT_NONE.value);
  const [routeDriverValue, setRouteDriverValue] = useState(AppConstants.SELECT_NONE.value);
  const [routeHelperValue, setRouteHelperValue] = useState(AppConstants.SELECT_NONE.value);
  const [routeVehicleValue, setRouteVehicleValue] = useState(AppConstants.SELECT_NONE.value);
  const [isPlannedRoute, setIsPlannedRoute] = useState(false);
  const [selectableConsignments, setSelectableConsignments] = useState({ ...slotwiseConsignments });
  const [checkedConsignments, setCheckedConsignments] = useState({} as any);
  const [selectedRoute, setSelectedRoute] = useState({} as any);
  const [addressVerified, setAddressVerified] = useState(false);
  const [addressAfterUnverrified, setAddressAfterUnverrified] = useState({} as any);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedConsignments, setSelectedConsignments] = useState({} as any);
  const [isExpressFlow, setIsExpressFlow] = useState(location.state && location.state.isExpress);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [unplannedConsignmentsData, setUnplannedConsignmentsData] = useState({ selectedConsignments: {}, consignmentsSequence: {} } as any);
  const [plannedConsignmentsData, setPlannedConsignmentsData] = useState({ selectedConsignments: {}, consignmentsSequence: {} } as any);
  const [openReleaseDialog, setOpenReleaseDialog] = useState(false);
  const [openReleaseSnackbar, setOpenReleaseSnackbar] = useState(false);
  const [currentDate, setCurrentDate] = useState({
    startDate: moment(location.state && location.state.plannedDate ? location.state.plannedDate : new Date(), AppConstants.DATE_FORMAT_BACKEND)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .format(AppConstants.DATE_FORMAT),
    endDate: moment(location.state && location.state.plannedDate ? location.state.plannedDate : new Date(), AppConstants.DATE_FORMAT_BACKEND)
      .hours(23)
      .minutes(59)
      .seconds(59)
      .format(AppConstants.DATE_FORMAT),
  });

  let singleEntryVehical = false;
  const isEditIcon = location?.state?.route?.displayRouteStatus !== AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.UNEDITABLE_CHIP;

  const confirmDialogPaperProps = { classes: { root: classes.confirmDialogPaperPropsRoot } };
  const confirmWithTitleDialogProps = { classes: { root: classes.confirmWithTitleDialogPaperPropsRoot } };
  const endSessionSelectionDialogPaperPropsRoot = { classes: { root: classes.endSessionSelectionDialogPaperPropsRoot } };

  const initialSteps = () => {
    if (viewMode || editMode) {
      sessionStorage.setItem("routeId", match?.params?.id);
    }
    if (editMode) {
      setNoRedirect(true);
      setSlotValue(location?.state?.slots);
    }
    onLoadRef.current = true;
    filterChangeRef.current = false;
    initializeRoute({ date: currentDate, slotValue: editMode ? location.state.slots : slotValue, statusValue: statusValue, routeType: location?.state?.route?.routeType || ROUTE_TYPE.NORMAL_ROUTE });
  };

  const initializeRoute = (params: any) => {
    setConsignmentsSequence({});
    setLastSequenceNumber(0);
    if (!viewMode) {
      if (filterChangeRef.current) {
        handleCreateDraftRoute({ slots: params.slotValue, statusValue: params.statusValue, zoneValue: params.zoneValue });
      } else {
        getAllSlots({
          date: params.date,
          slotValue: params.slotValue,
          editMode: editMode,
          hubCode: location.state.hubCode,
          hubName: hubNameRef.current,
          statusValue: params.statusValue,
          isExpress: isExpressFlow,
          zoneValue: params?.zoneValue,
          routeType: params?.routeType,
        });
        if (location?.state?.route?.routeType === ROUTE_TYPE.PARENT_ROUTE && location?.state?.route?.id && loadDashIsEmpty(subRoutes)) {
          getSubRoutes(location?.state?.route?.id);
        }
        if (loadDashIsEmpty(routeConsignments) && location?.state?.route?.id) {
          getRouteConsignments(location?.state?.route?.id);
        }
      }

      if (!editMode) {
        setDriverValue(AppConstants.SELECT_NONE.value);
        setHelperValue(AppConstants.SELECT_NONE.value);
        setVehicleValue(AppConstants.SELECT_NONE.value);
      }
    } else {
      if (location?.state?.parentRoute === ROUTE_TYPE.PARENT_ROUTE && match?.params?.id) {
        getSubRoutes(match.params.id);
      } else {
        handleResetViewModeState();
      }
      getRouteConsignments(match.params.id); // call route api and set selected cons
    }
  };

  const handleCreateDraftRoute = (params: any) => {
    const payload = createDraftRoutePayload({
      date: currentDate,
      slotValue: params.slots,
      editMode: editMode,
      hubCode: location.state.hubCode,
      hubName: hubNameRef.current,
      statusValue: params.statusValue,
      isExpress: isExpressFlow,
      zoneValue: params?.zoneValue,
    });
    dispatch(createDraftRoute({ params: payload }));
  };

  const getSubRoutes = (id: string) => {
    const params = { routeId: id, fetchOrders: true };
    dispatch(fetchSubRouteById({ params: params }));
  };

  const handleResetViewModeState = () => {
    dispatch(resetViewRouteState());
  };

  const getRouteConsignments = (id: string) => {
    dispatch(fetchRouteConsignments({ id: id }));
  };

  const handleOpenReleaseDriverHelperDialog = () => {
    setOpenReleaseDialog(true);
  };

  const handleReleaseDialogClose = () => {
    setOpenReleaseDialog(false);
  };

  const handleReleaseAssigneeSelection = (id: any) => {
    const payload = createPayloadToReleaseAssignee(match.params.id);
    dispatch(releaseAssignee({ params: payload }));
    setOpenReleaseDialog(false);
    setOpenReleaseSnackbar(true);
  };

  const handleRefreshPlannedRoutes = () => {
    if (isExpressFlow) {
      getAllPlannedRoutes({ date: currentDate, hubCode: location.state.hubCode, isExpress: isExpressFlow });
    } else {
      getAllPlannedRoutes({ date: currentDate, hubCode: location.state.hubCode });
    }
  };

  const setExpandedConsignments = (consignments: string[], isSearch?: boolean) => {
    const expandedConsignments = getUniqueValues(consignments);
    isSearch ? setSearchedConsignmentExpanded(expandedConsignments) : setConsignmentExpanded(expandedConsignments);
  };

  const handleSearchedAccordionChange = (searchValue: string) => {
    if (searchValue) {
      let expandedConsignments: string[] = [];
      let searchedConsignments = allConsignments.filter((item: any) => {
        const consignmentValue = highlightTextWithClass(searchValue, item.code);
        const address = getAddressInfo(item.data && item.data.deliveryInformation ? item.data.deliveryInformation.customerContactInfo.address : "");
        const addressValue = highlightTextWithClass(searchValue, address);
        const areaValue = highlightTextWithClass(searchValue, item.area);
        return consignmentValue.indexOf("highlightText") > -1 || addressValue.indexOf("highlightText") > -1 || areaValue.indexOf("highlightText") > -1;
      });
      if (searchedConsignments?.length > 0) {
        searchedConsignments.forEach((consignment: any) => {
          const slotIndex = expandedConsignments.indexOf(consignment.slot);
          const areaIndex = expandedConsignments.indexOf(consignment.area);
          if (slotIndex === -1) {
            expandedConsignments.push(`${consignment.area}-${consignment.slot}`);
          }
          if (areaIndex === -1) {
            expandedConsignments.push(consignment.area);
          }
        });
      }
      setExpandedConsignments(expandedConsignments, true);
    }
  };

  const debounceConsignmentSearch = _.debounce((searchValue: string) => {
    handleSearchedAccordionChange(searchValue);
  }, 500);

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    setConsignmentSearchValue(searchValue);
    debounceConsignmentSearch(searchValue);
  };

  const handleChangeRoute = (params: any) => {
    if (!filterChangeRef.current) {
      onLoadRef.current = true;
      setSlotValue([AppConstants.SELECT_NONE.value]);
    }
    initializeRoute({ date: params.date, slotValue: params.slotValue, statusValue: params.statusValue, zoneValue: params.zoneValue });
    setSelectedConsignments({});
    setSelectedRoute({});
    setUnplannedConsignmentsData({ selectedConsignments: {}, consignmentsSequence: {} });
    setPlannedConsignmentsData({ selectedConsignments: {}, consignmentsSequence: {} });
    setConsignmentSearchValue("");
    setExpandedConsignments([], true);
    setExpandedConsignments([]);
    setToggleAllDropdownExpanded(false);
    autoRefreshMapRef.current = true;
    setOptimizeMapRoute(false);
    history.replace({ ...location, state: { ...location.state, plannedDate: moment(params.date.startDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT_BACKEND) } });
  };

  const handleStatusChange = useCallback(
    (value: any) => {
      filterChangeRef.current = true;
      setStatusValue(value);
      if (shouldBlockNavigation) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
        setOpenDateChangeConfirmDialog(true);
      } else {
        handleChangeRoute({ date: currentDate, slotValue: slotValue, statusValue: value, zoneValue: zoneValue });
      }
    },
    [currentDate, slotValue, zoneValue, shouldBlockNavigation, handleChangeRoute]
  );

  const handleSlotChange = useCallback(
    (value: any) => {
      onLoadRef.current = false;
      filterChangeRef.current = true;
      setSlotValue(value);
      if (shouldBlockNavigation) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
        setOpenDateChangeConfirmDialog(true);
      } else {
        handleChangeRoute({ date: currentDate, statusValue: statusValue, slotValue: value, zoneValue: zoneValue });
      }
    },
    [currentDate, statusValue, zoneValue, shouldBlockNavigation, handleChangeRoute]
  );

  const handleZoneChange = useCallback(
    (value: any) => {
      onLoadRef.current = false;
      filterChangeRef.current = true;
      setZoneValue(value);
      if (shouldBlockNavigation) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
        setOpenDateChangeConfirmDialog(true);
      } else {
        handleChangeRoute({ date: currentDate, statusValue: statusValue, slotValue: slotValue, zoneValue: value });
      }
    },
    [currentDate, statusValue, slotValue, shouldBlockNavigation, handleChangeRoute]
  );

  const handleDateChange = useCallback(
    (dateRange: any) => {
      filterChangeRef.current = false;
      setCurrentDate(dateRange);
      setSlotValue([AppConstants.SELECT_NONE.value]);
      setStatusValue([AppConstants.SELECT_NONE.value]);
      setZoneValue([]);
      if (shouldBlockNavigation) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
        setOpenDateChangeConfirmDialog(true);
      } else {
        handleChangeRoute({ date: dateRange, statusValue: [], slotValue: [], zoneValue: [] });
      }
    },
    [shouldBlockNavigation, handleChangeRoute]
  );

  const getViewModeConsignments = () => {
    const renderConsignmentItem = (consignmentItem: any) => (
      <Grid key={consignmentItem?.consignmentCode || Math.random()} container className="slotItemContainer">
        <Grid className="slotContentItem consignmentDetails" item>
          <Grid className="consignmentDetailsContainer" container>
            <Grid className="detailsItem consignment" item>
              <Grid container className="consignmentHeader">
                <Grid item className="consignmentHeaderItem heading">
                  <Grid container className="headingContainer">
                    <Grid className="headingItem" onClick={() => handleConsignmentDetailsClick(consignmentItem)} item>
                      <Typography className="heading">{consignmentItem?.consignmentCode}</Typography>
                    </Grid>
                    {consignmentItem?.deliveryInformation?.deliverySlot?.rescheduled && <RenderRescheduleOrders />}
                    <Grid className="headingItem status" item>
                      <Grid className={classes.statusHeaderContainer} container alignItems="center">
                        <Typography className={["tag", getConsignmentStatusField("color", consignmentItem?.metadata?.deliveryStatus)].join(" ")}>
                          {consignmentItem ? consignmentItem?.metadata?.deliveryStatus : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="consignmentHeaderItem details">
                  <Grid container className="headerDetailsContainer">
                    <Grid className="detailsContainerItem crates" item>
                      <Grid className="itemContainer" container>
                        <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                        <Typography className="count">{consignmentItem && consignmentItem?.deliveryInformation?.totalCratesCount}</Typography>
                      </Grid>
                    </Grid>
                    <Grid className="detailsContainerItem location" item>
                      <Grid className="itemContainer" container>
                        <SvgIcon className="icon" viewBox="0 0 16 16" component={locationIcon} />
                        <Typography className="count locDesc">
                          {consignmentItem?.routeType === ROUTE_TYPE.SUB_ROUTE && consignmentItem?.deliveryInformation?.customerContactInfo?.address}
                          {consignmentItem?.routeType !== ROUTE_TYPE.SUB_ROUTE &&
                            getAddressInfo(consignmentItem?.deliveryInformation?.customerContactInfo?.address ? consignmentItem.deliveryInformation.customerContactInfo.address : "")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    const renderSubRouteConsignments = (subRoute: any) => (
      <Accordion className={classes.subRoute} key={subRoute.routeId} TransitionProps={{ unmountOnExit: true, timeout: 0 }}>
        <AccordionSummary expandIcon={<SvgIcon component={downArrowIcon} />}>
          {subRoute.routeId}
          <Typography className="tag status">{subRoute?.displayStatus}</Typography>
          <Typography className="tag subRouteTag">{ALL_ROUTES_TEXT_CONTANT.SUB_ROUTE}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.viewModeSubroute}>{subRoute.orders.map(renderConsignmentItem)}</AccordionDetails>
      </Accordion>
    );

    return (
      <>
        {routeConsignments?.length > 0 && routeConsignments.map(renderConsignmentItem)}
        {subRoutes?.length > 0 && subRoutes.map(renderSubRouteConsignments)}
        {routeConsignments?.length === 0 && subRoutes?.length === 0 && <Typography className="padding-2">No Items to display</Typography>}
      </>
    );
  };

  const getAllSelectedConsignments = useCallback(() => {
    let consignments: any[] = [];
    if (selectedConsignments) {
      Object.keys(selectedConsignments).forEach((areaKey: string) => {
        const slots = selectedConsignments[areaKey];
        Object.keys(slots).forEach((slotKey: string) => {
          consignments = [...consignments, ...slots[slotKey].filter((item: any) => item.value !== AppConstants.SELECT_ALL.value)];
        });
      });
    }
    return consignments;
  }, [selectedConsignments]);

  const updateAllConsignments = (consignments: any) => {
    dispatch(updateAllConsignmentsState({ consignments: consignments }));
  };

  const getAllDrivers = (params: any) => {
    const payload = createGetAllDropdownsPayload(params);
    dispatch(fetchAllDrivers({ payload: payload }));
  };

  const getAllHelpers = (params: any) => {
    const payload = createGetAllDropdownsPayload(params);
    dispatch(fetchAllHelpers({ payload: payload }));
  };

  const getAllVehicles = (params: any) => {
    const payload = createGetAllDropdownsPayload(params);
    dispatch(fetchAllVehicles({ payload: payload }));
  };

  const updateConsignments = async () => {
    const consignments = await getConsignmentData(draftRoute.statusOrders);
    updateAllConsignments({ ...consignments, loading: false });
    getAllDrivers({ date: currentDate, hubCode: draftRoute.route.hubCode });
    getAllHelpers({ date: currentDate, hubCode: draftRoute.route.hubCode });
    getAllVehicles({ date: currentDate, hubCode: draftRoute.route.hubCode });
  };

  const getAreawiseConsignments = () => {
    return areawiseConsignments.length ? (
      areawiseConsignments.map((areaItem: any) => (
        <Accordion expanded={checkExpanded(areaItem)} onChange={handleConsignmentAccordionChange(areaItem)} key={areaItem.code} className="accordionRoot delArea">
          <AccordionSummary expandIcon={<SvgIcon component={downArrowIcon} />} aria-controls="delivery-area-content" className="accordianSummary delArea">
            <Grid className="areaHeaderContainer" container>
              <Grid className="areaHeaderItem area" item>
                <Grid className="itemContainer" container>
                  <Typography className="heading" dangerouslySetInnerHTML={{ __html: updateHighlightSearch({ code: areaItem.value }) }} />
                </Grid>
              </Grid>
              <Grid className="areaHeaderItem consignment" item>
                <Grid className="itemContainer" container>
                  <SvgIcon className="icon" viewBox="0 0 16 16" component={consignmentsIcon} />
                  <Typography className="count">{getAreaTotalConsignments(areaItem).padStart(2, "0")}</Typography>
                </Grid>
              </Grid>
              <Grid className="areaHeaderItem crates" item>
                <Grid className="itemContainer" container>
                  <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                  <Typography className="count">{getAreaTotalCrates(areaItem).padStart(2, "0")}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className="accordianDetails delArea">
            <Grid className="delSlotContainer" container>
              {areaItem.data &&
                areaItem.data.length &&
                areaItem.data.map((slotItem: any) => (
                  <Accordion expanded={checkExpanded(slotItem, true)} onChange={handleConsignmentAccordionChange(slotItem, true)} key={slotItem.code} className="accordionRoot delSlot">
                    <AccordionSummary expandIcon={<SvgIcon component={downArrowIcon} />} aria-controls="delivery-slot-content" className="accordianSummary delSlot">
                      <Grid className="slotHeaderContainer" container>
                        <Grid className="slotHeaderItem slot" item>
                          <Grid className="itemContainer" container>
                            {isRouteStatusOutForDelivery(location?.state?.route?.status) || draftRoute?.route?.routeType === ROUTE_TYPE.PARENT_ROUTE || draftRoute?.route?.routeType === ROUTE_TYPE.SUB_ROUTE ? (
                              <></>
                            ) : (
                              <Checkbox
                                color="primary"
                                checked={getSelectedValue(slotItem, true)}
                                disabled={disableCheckboxForPlanned(slotItem, true)}
                                onClick={(e: any) => handleCheckboxToggle(e, slotItem, true)}
                              />
                            )}
                            <Typography className="heading">{slotItem.value}</Typography>
                          </Grid>
                        </Grid>
                        <Grid className="slotHeaderItem consignment" item>
                          <Grid className="itemContainer" container>
                            <SvgIcon className="icon" viewBox="0 0 16 16" component={consignmentsIcon} />
                            <Typography className="count">{`${slotItem.data.length}`.padStart(2, "0")}</Typography>
                          </Grid>
                        </Grid>
                        <Grid className="slotHeaderItem crates" item>
                          <Grid className="itemContainer" container>
                            <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                            <Typography className="count">{`${getTotalCrates(slotItem.data)}`.padStart(2, "0")}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className="accordianDetails delSlot">
                      <Grid className="slotContentContainer" container>
                        {slotItem.data &&
                          slotItem.data.length &&
                          slotItem.data.map((consignmentItem: any) => (
                            <Grid
                              key={consignmentItem.code}
                              container
                              className={`slotItemContainer ${getSelectedValue(consignmentItem, false) ? "selected" : ""} ${
                                consignmentItem.status === "DRAFTED" ? "drafted" : checkTerminalStatus(consignmentItem.data.metadata.deliveryStatus) ? "terminal" : ""
                              }`}
                            >
                              <Grid className="slotContentItem checkbox" item>
                                <Grid container className="checkboxContainer">
                                  {consignmentItem.status === "DRAFTED" && (
                                    <CustomTooltip placement="bottom-start" title={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.DRAFTED_CONSIGNMENT_TOOLTIP_MSG}>
                                      <SvgIcon className="lockIcon" viewBox="0 0 12 12" component={lockIcon} />
                                    </CustomTooltip>
                                  )}
                                  {isRouteStatusOutForDelivery(location?.state?.route?.status) || draftRoute?.route?.routeType === ROUTE_TYPE.PARENT_ROUTE || draftRoute?.route?.routeType === ROUTE_TYPE.SUB_ROUTE ? (
                                    <></>
                                  ) : (
                                       <Checkbox
                                      disabled={consignmentItem.status === "DRAFTED" || checkTerminalStatus(consignmentItem?.data?.metadata?.deliveryStatus)}
                                      checked={getSelectedValue(consignmentItem, false)}
                                      onClick={(e: any) => handleCheckboxToggle(e, consignmentItem, false)}
                                      color="primary"
                                    />
                                  )}
                                </Grid>
                              </Grid>
                              <Grid className="slotContentItem consignmentDetails" item>
                                <Grid className="consignmentDetailsContainer" container>
                                  <Grid className="detailsItem consignment" item>
                                    <Grid container className="consignmentHeader">
                                      <Grid item className="consignmentHeaderItem heading">
                                        <Grid container className="headingContainer">
                                          <Grid className="headingItem" onClick={() => handleConsignmentDetailsClick(consignmentItem.data)} item>
                                            <Typography className="heading" dangerouslySetInnerHTML={{ __html: updateHighlightSearch(consignmentItem) }} />
                                          </Grid>
                                          {consignmentItem?.data?.deliveryInformation?.deliverySlot?.rescheduled && <RenderRescheduleOrders />}
                                          <Grid className="headingItem status" item>
                                            <Grid className={classes.statusHeaderContainer} container alignItems="center">
                                              <Typography className={["tag", getConsignmentStatusField("color", consignmentItem.data.metadata.deliveryStatus)].join(" ")}>
                                                {consignmentItem.data ? consignmentItem.data.metadata.deliveryStatus : ""}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item className="consignmentHeaderItem details">
                                        <Grid container className="headerDetailsContainer">
                                          <Grid className="detailsContainerItem crates" item>
                                            <Grid className="itemContainer" container>
                                              <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                                              <Typography className="count">{consignmentItem.data && consignmentItem.data.deliveryInformation.totalCratesCount}</Typography>
                                            </Grid>
                                          </Grid>
                                          <Grid className="detailsContainerItem location" item>
                                            <Grid className="itemContainer" container>
                                              <SvgIcon className="icon" viewBox="0 0 16 16" component={locationIcon} />
                                              {addressAfterUnverrified[consignmentItem.code] === ADDRESS_STATUS.UNVERIFIED ? (
                                                <Grid item className="consignmentAddressIcon">
                                                  <img src={unverifiedIcon} alt="info-icon" />
                                                </Grid>
                                              ) : (
                                                <Grid item className="consignmentAddressIcon">
                                                  {addressStatus(consignmentItem.data.deliveryInformation.customerContactInfo.address.status)}
                                                </Grid>
                                              )}
                                              <Typography
                                                className="count locDesc"
                                                dangerouslySetInnerHTML={{
                                                  __html: updateHighlightSearch({
                                                    code: getAddressInfo(
                                                      consignmentItem.data && consignmentItem.data.deliveryInformation ? consignmentItem.data.deliveryInformation.customerContactInfo.address : ""
                                                    ),
                                                  }),
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {consignmentItem?.data?.deliveryInformation?.customerContactInfo?.address?.status !== ADDRESS_STATUS.UNVERIFIED &&
                                        consignmentItem?.data?.deliveryInformation?.customerContactInfo?.address?.status !== ADDRESS_STATUS.VERIFIED &&
                                        !addressAfterUnverrified[consignmentItem.code] && (
                                          <Grid className="consignmentHeaderItem details status" onClick={() => handleChange(consignmentItem.code)} item>
                                            <Typography className="consignmentAddressCTA">Mark address as inaccurate</Typography>
                                          </Grid>
                                        )}
                                    </Grid>
                                  </Grid>
                                  <Grid className="detailsItem sequence" item>
                                    {getSelectedValue(consignmentItem, false) && (
                                      <Grid className="seqNoContainer">
                                        <Typography className="seqNo">{consignmentsSequence[consignmentItem.code] && `${consignmentsSequence[consignmentItem.code].seq}`.padStart(2, "0")}</Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))
    ) : (
      <Typography className="padding-2">No Items to display</Typography>
    );
  };

  const designAllRoutesStateObject = () => {
    if (isNonEmptyObject(plannedRoutesNew) && !isUndefined(fetchStatus) && fetchStatus !== (API_RESPONSE_STATUS.IDLE || API_RESPONSE_STATUS.LOADING || API_RESPONSE_STATUS.FAILED)) {
      let newAllRouteState = designAllRoutesStateObjectUtils(allRouteState, plannedRoutesNew);
      setAllRouteState(newAllRouteState);
    }
  };

  const addressStatus = (status: any) => {
    if (status === ADDRESS_STATUS.VERIFIED) {
      return <img src={verifiedIcon} alt="verified-icon" />;
    } else {
      if (status === ADDRESS_STATUS.UNVERIFIED) {
        return <img src={unverifiedIcon} alt="unverified-icon" />;
      } else {
        return <img src={pendingIcon} alt="pending-icon" />;
      }
    }
  };

  const getAllPlannedRoutes = (params: any) => {
    const payload = createGetAllPlannedRoutesPayload(params);
    dispatch(fetchAllPlannedRoutesNew({ payload: payload }));
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, value: string) => {
    setTabValue(value);
    setAutoRefreshMap(true);
    setSelectedRoute({});
    if (value === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED) {
      setPlannedConsignmentsData({ selectedConsignments: { ...selectedConsignments }, consignmentsSequence: { ...consignmentsSequence } });
      setSelectedConsignments(unplannedConsignmentsData.selectedConsignments);
      setConsignmentsSequence(unplannedConsignmentsData.consignmentsSequence);
    } else {
      setUnplannedConsignmentsData({ selectedConsignments: { ...selectedConsignments }, consignmentsSequence: { ...consignmentsSequence } });
      setSelectedConsignments(plannedConsignmentsData.selectedConsignments);
      setConsignmentsSequence(plannedConsignmentsData.consignmentsSequence);
      handleRefreshPlannedRoutes();
    }
  };

  const toggleListView = (type: string) => {
    setIsListView(type === "map" ? false : true);
    if (type === "map") {
      setAutoRefreshMap(true);
    } else {
      setAutoRefreshMap(false);
      setCreateRouteState({ ...createRouteState, refreshInterval: 0 });
    }
  };

  const handleConsignmentAccordionChange = useCallback(
    (itemObj: any, isSlot?: boolean) => (event: any, isExpanded: boolean) => {
      let expandedConsignments = consignmentSearchValue ? [...searchedConsignmentExpanded] : [...consignmentExpanded];
      const id = isSlot ? `${itemObj.area}-${itemObj.code}` : itemObj.code;
      const idIndex = expandedConsignments.indexOf(id);
      const allSlotItems = !isSlot ? Object.keys(slotwiseConsignments[itemObj.code]) : [];
      if (isExpanded) {
        expandedConsignments.push(id);
        if (allSlotItems.length) {
          expandedConsignments = [...expandedConsignments, ...allSlotItems.map((item) => `${itemObj.code}-${item}`)];
        }
      } else {
        if (idIndex > -1) {
          expandedConsignments.splice(idIndex, 1);
        }
      }

      consignmentSearchValue ? setExpandedConsignments(expandedConsignments, true) : setExpandedConsignments(expandedConsignments);
    },
    [consignmentExpanded, consignmentSearchValue, searchedConsignmentExpanded, slotwiseConsignments, setExpandedConsignments]
  );

  const checkExpanded = useCallback(
    (item: any, isSlot?: boolean) => {
      const idsToCheck = consignmentSearchValue ? [...searchedConsignmentExpanded] : [...consignmentExpanded];
      let expanded = false;
      const itemId = isSlot ? `${item.area}-${item.code}` : item.code;
      if (item) {
        if (idsToCheck.length) {
          expanded = idsToCheck.indexOf(itemId) > -1;
        } else {
          expanded = false;
        }
      }
      return expanded;
    },
    [consignmentExpanded, consignmentSearchValue, searchedConsignmentExpanded]
  );

  const calculateCapacityBreach = (type: string) => {
    let capacityBreach = false;
    if (vehiclesData && vehicleValue !== AppConstants.SELECT_NONE.value) {
      let selectedVehicle = vehiclesData.filter((vehicle: any) => vehicle.id === vehicleValue);
      if (selectedVehicle.length) {
        selectedVehicle = selectedVehicle[0];
        const allSelectedConsignments = getAllSelectedConsignments();
        switch (type) {
          case "consignments":
            capacityBreach = allSelectedConsignments.length > selectedVehicle.orderCapacity;
            break;
          case "crates":
            capacityBreach = getTotalCrates(allSelectedConsignments) > selectedVehicle.crateCapacity;
            break;
          case "transitTime":
            capacityBreach = false;
            break;
          default:
            break;
        }
      }
    }
    return capacityBreach;
  };

  const updateHighlightSearch = useCallback(
    (item: any) => {
      let returnValue = item.code;
      if (consignmentSearchValue) {
        returnValue = highlightTextWithClass(consignmentSearchValue, item.code);
      }
      return returnValue;
    },
    [consignmentSearchValue]
  );

  const handleConSelectChange = useCallback(
    (newValues: any, slot: string, area: string) => {
      let selected: any[] = [];
      const selectedValues = newValues[area][slot];
      const previous = selectedConsignments[area][slot].map((item: any) => item.value);
      const selectable = selectableConsignments[area][slot].filter((item: any) => item.status !== "DRAFTED");
      if (selectedValues.length) {
        selectable.forEach((selItem: any) => {
          selectedValues.forEach((item: any) => {
            if (selItem.value === item) {
              selected.push(selItem);
            }
          });
        });
      }
      const selectAllIdx = selectedValues.indexOf(AppConstants.SELECT_ALL.value);
      const selectAllPreviousIdx = previous.indexOf(AppConstants.SELECT_ALL.value);
      if (selectAllIdx > -1) {
        if (selected.length < selectable.length) {
          if (selectAllPreviousIdx > -1) {
            selected = selected.filter((item: any) => item.value !== AppConstants.SELECT_ALL.value && item.status !== "DRAFTED");
          } else {
            selected = selectable.slice().filter((item: any) => item.status !== "DRAFTED");
          }
        }
      } else {
        if (selected.length < selectable.length) {
          if (selectAllPreviousIdx > -1) {
            selected = [];
          } else if (selected.length === selectable.length - 1) {
            selected = selectable.slice().filter((item: any) => item.status !== "DRAFTED");
          }
        }
      }
      if (!selected.length) {
        selected = [];
      }
      setSelectedConsignments({ ...selectedConsignments, [area]: { ...selectedConsignments[area], [slot]: selected } });
      setCheckedConsignments({ ...checkedConsignments, [area]: { ...checkedConsignments[area], [slot]: selected.map((item: any) => item.value) } });
      let defaultConsignments = [AppConstants.SELECT_ALL];
      if (editMode) {
        defaultConsignments =
          draftRoute.route && draftRoute.route.consignmentsIds
            ? [
                AppConstants.SELECT_ALL,
                ...draftRoute.route.consignmentsIds.map((item: string) => {
                  return {
                    value: item,
                  };
                }),
              ]
            : [AppConstants.SELECT_ALL];
      }
      let allPreviousSelectedConsignments = getAllSelectedConsignments();
      const currentSlotPreviousConsignments = allPreviousSelectedConsignments.filter((con: any) => con.area === area && con.slot === slot);
      allPreviousSelectedConsignments = _.difference(allPreviousSelectedConsignments, currentSlotPreviousConsignments);
      const allSelectedConsignments = [...allPreviousSelectedConsignments, ...selected];
      sessionStorage.setItem("extraConsignments", JSON.stringify(getDifferenceValues(allSelectedConsignments, defaultConsignments).map((item: any) => item.value)));
    },
    [selectableConsignments, selectedConsignments, checkedConsignments, draftRoute, editMode, getAllSelectedConsignments]
  );

  const updateOrderStatus = (params: any) => {
    if (params.consignments.length) {
      const payload = createUpdateOrderStatusPayload(params);
      dispatch(updateConsignmentStatus({ payload: payload }));
    }
  };

  const handleConsignmentSequense = (consignment: any, isNew: boolean, seqParams: any) => {
    if (consignment) {
      let lastSeqNo: number = seqParams.lastSeqNo;
      let newSequencedCons = { ...seqParams.allSequencedCons };
      if (isNew) {
        newSequencedCons[consignment.id] = { id: consignment.id, seq: ++lastSeqNo };
      } else {
        delete newSequencedCons[consignment.id];
        --lastSeqNo;
        Object.keys(newSequencedCons).forEach((id: string) => {
          const cons = newSequencedCons[id];
          if (id !== consignment.id && cons.seq >= consignment.seq) {
            newSequencedCons[id].seq -= 1;
          }
        });
      }
      seqParams.lastSeqNo = lastSeqNo;
      seqParams.allSequencedCons = newSequencedCons;
    }
    return seqParams;
  };

  const addSequence = (consignments: any[], status: string, params: any) => {
    let seqParams = _.cloneDeep(params);
    if (consignments.length) {
      let sequencedCons = seqParams.allSequencedCons;
      consignments.forEach((id: any) => {
        let isNew = status === "PLANNED";
        let consignment = isNew ? { id: id } : sequencedCons[id];
        seqParams = handleConsignmentSequense(consignment, isNew, seqParams);
      });
    }
    return seqParams;
  };

  const handleCheckboxToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | MouseEvent>, value: any, isSlot: boolean) => {
      event.stopPropagation();
      const currentSlot = isSlot ? value.code : value.slot;
      const currentArea = value.area;
      const currentValue = isSlot ? AppConstants.SELECT_ALL.value : value.code;
      const currentIndex = checkedConsignments[currentArea][currentSlot].indexOf(currentValue);
      const newCheckedConsignments = [...checkedConsignments[currentArea][currentSlot]];
      let consignments = [];
      let status = "";
      if (currentIndex === -1) {
        status = "PLANNED";
        if (isSlot) {
          consignments = slotwiseConsignments[currentArea][currentSlot]
            .slice()
            .filter((item: any) => item.status !== "DRAFTED")
            .map((item: any) => item.code);
        } else {
          consignments = [currentValue];
        }
        newCheckedConsignments.push(currentValue);
      } else {
        status = "UNPLANNED";
        if (isSlot) {
          consignments = slotwiseConsignments[currentArea][currentSlot]
            .slice()
            .filter((item: any) => item.status !== "DRAFTED")
            .map((item: any) => item.code);
        } else {
          consignments = [currentValue];
        }
        newCheckedConsignments.splice(currentIndex, 1);
      }
      updateOrderStatus({ consignments: consignments, status: status, routeId: draftRoute.route.id });
      let seqParams: any = { allSequencedCons: _.cloneDeep(consignmentsSequence), lastSeqNo: lastSequenceNumber };
      if (isSlot && status === "PLANNED") {
        const alreadySelectedCons = getMatchingValues(checkedConsignments[currentArea][currentSlot], consignments);
        seqParams = addSequence(alreadySelectedCons, "UNPLANNED", seqParams);
      }
      seqParams = addSequence(consignments, status, seqParams);
      autoRefreshMapRef.current = false;
      setOptimizeMapRoute(false);
      setAutoRefreshMap(false);
      setUpdateMap(false);
      setConsignmentsSequence(seqParams.allSequencedCons);
      setLastSequenceNumber(seqParams.lastSeqNo);
      setCheckedConsignments({ ...checkedConsignments, [currentArea]: { ...checkedConsignments[currentArea], [currentSlot]: newCheckedConsignments } });
      handleConSelectChange({ ...checkedConsignments, [currentArea]: { ...checkedConsignments[currentArea], [currentSlot]: newCheckedConsignments } }, currentSlot, currentArea);
    },
    [checkedConsignments, draftRoute, slotwiseConsignments, consignmentsSequence, lastSequenceNumber, addSequence, handleConSelectChange, updateOrderStatus]
  );

  const handleRouteSelection = (route: any) => {
    setSelectedRoute(route);
    if (route?.data?.assignee) {
      setRouteDriverValue(route?.data?.assignee?.id);
    } else {
      setRouteDriverValue(AppConstants.SELECT_NONE.value);
    }
    if (route?.data?.helper) {
      setRouteHelperValue(route?.data?.helper?.id);
    } else {
      setRouteHelperValue(AppConstants.SELECT_NONE.value);
    }
    if (route.data.assignedVehicle) {
      setRouteVehicleValue(route.data.assignedVehicle.id);
    } else {
      setRouteVehicleValue(AppConstants.SELECT_NONE.value);
    }
    getRouteConsignments(route?.code);
    if (route?.data?.routeType === ROUTE_TYPE.PARENT_ROUTE) {
      getSubRoutes(route.code);
    } else {
      handleResetViewModeState();
    }
  };

  const handleDriverChange = (value: any) => {
    setDriverValue(value);
  };

  const handleRouteDriverChange = (value: any) => {
    setRouteDriverValue(value);
  };

  const handleHelperChange = (value: any) => {
    setHelperValue(value);
  };

  const handleRouteHelperChange = (value: any) => {
    setRouteHelperValue(value);
  };

  const handleVehicleChange = (value: any) => {
    setVehicleValue(value);
  };

  const handleRouteVehicleChange = (value: any) => {
    setRouteVehicleValue(value);
  };

  const getAllSlots = (params: any) => {
    const payload = createDraftRoutePayload(params);
    dispatch(loadAllSlots({ params: payload }));
  };

  const getSelectedValue = useCallback(
    (item: any, isSlot: boolean) => {
      const slot = isSlot ? item.code : item.slot;
      const area = item.area;
      let selectedValues = [];
      if (selectedConsignments[area] && selectedConsignments[area][slot] && selectedConsignments[area][slot].length) {
        selectedValues = selectedConsignments[area][slot].map((con: any) => con.value);
      }
      return selectedValues.indexOf(isSlot ? AppConstants.SELECT_ALL.value : item.value) > -1;
    },
    [selectedConsignments]
  );

  const disableCheckboxForPlanned = useCallback(
    (item: any, isSlot: boolean) => {
      const slot = isSlot ? item.code : item.slot;
      const area = item.area;
      let selectedValues = [];
      if (selectedConsignments[area] && selectedConsignments[area][slot] && selectedConsignments[area][slot].length) {
        selectedValues = selectedConsignments[area][slot].filter((con: any) => con.status == "PLANNED");
      }
      return selectedValues?.length;
    },
    [selectedConsignments]
  );

  const getChangeDialogTitleContent = () => {
    return <></>;
  };

  const getDiscardDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>All Changes will be lost. Are you sure you want to discard changes?</Typography>
        </Grid>
      </Grid>
    );
  };

  const getChangeDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>All Changes will be lost. Are you sure you want to continue?</Typography>
        </Grid>
      </Grid>
    );
  };

  const getReleaseDialogTitleContent = () => {
    return (
      <>
        <Grid container style={{ padding: "8px" }}>
          <img src={alertIcon} alt="alert-icon" />
          <Typography className={classes.endSessionSelectionDialogTitle}>{AppConstants.BUTTONS.END_DRIVER_HELPER_SESSION}</Typography>
        </Grid>
      </>
    );
  };

  const getReleaseDialogDetailsContent = () => {
    return (
      <Grid className={classes.endSessionSelectionDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography className={classes.content}>{AppConstants.USERS_CONSTANTS.RELEASE_ASSIGNEE_CONTENT}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getReleaseDialogActions = () => {
    return (
      <Grid className={classes.endSessionDialogButtonsContainer} container>
        <Button className="dialogBtn secondary" variant="contained" onClick={handleReleaseDialogClose}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className="dialogBtn primary" variant="contained" onClick={handleReleaseAssigneeSelection}>
          {AppConstants.BUTTONS.CONFIRM}
        </Button>
      </Grid>
    );
  };

  const handleDiscardDialogClose = () => {
    setOpenDiscardConfirmDialog(false);
  };

  const handleChangeDialogClose = () => {
    setOpenDateChangeConfirmDialog(false);
  };

  const handleDiscardRoute = (redirect?: boolean) => {
    // const payload = getDiscardRoutePayload({ previousCons: draftRoute.route ? draftRoute.route.consignmentsIds : [] });
    // dispatch(discardRouteChanges({ params: payload, redirect: redirect }));
    let route: any = AppConstants.ROUTES.PLANNING;
    let state: any = { tabValue: AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES };
    if (location.state && location.state.redirect) {
      route = location.state.redirect.route;
      state = updatedRoute ? { ...location.state.redirect.state, route: updatedRoute.route } : location.state.redirect.state;
      onLoadRef.current = true;
      filterChangeRef.current = false;
      setEditRoute(null);
    }
    handleClearCurrentSlot();
    history.push(route, state);
  };

  const discardDialogActionHandler = (type: string) => {
    if (type === "discard") {
      setNoRedirect(false);
      handleDiscardRoute();
    }
    handleDiscardDialogClose();
  };

  const getDiscardDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button
          className="dialogBtn secondary"
          variant="contained"
          onClick={() => {
            discardDialogActionHandler("cancel");
          }}
        >
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button
          className="dialogBtn primary"
          variant="contained"
          onClick={() => {
            discardDialogActionHandler("discard");
          }}
        >
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const getChangeDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button
          className="dialogBtn secondary"
          variant="contained"
          onClick={() => {
            changeDialogActionHandler("cancel");
          }}
        >
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button
          className="dialogBtn primary"
          variant="contained"
          onClick={() => {
            changeDialogActionHandler("proceed");
          }}
        >
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const getUpdateDialogTitleContent = () => {
    return <></>;
  };

  const getCapacityBreachDialogTitleContent = () => {
    return <Typography className={classes.capacityBreachDialogTitle}>Capacity Breach</Typography>;
  };

  const getCapacityBreachDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem desc" item>
          <Typography className="alignLeft">{capacityBreachPopupMsg}</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleCapacityBreachDialogClose = () => {
    setOpenCapacityBreachConfirmDialog(false);
  };

  const getOrderDetailById = (id: any) => {
    dispatch(fetchOrderById({ id: id }));
  };

  const handleConsignmentDetailsClick = (data: any) => {
    if (data.routeType === ROUTE_TYPE.SUB_ROUTE) {
      getOrderDetailById(data?.consignmentCode);
    } else {
      setConsignmentData(data);
    }
    setOpenConsignmentDetailsPopup(true);
  };

  const closeConsignmentDetailsPopup = () => {
    setOpenConsignmentDetailsPopup(false);
  };

  const getSeqSortedSelectedConsignments = () => {
    let seqSelectedConsignments = getAllSelectedConsignments().map((consignment: any) => {
      return { ...consignment, seq: consignmentsSequence[consignment.code] ? consignmentsSequence[consignment.code].seq : lastSequenceNumber };
    });
    return _.sortBy(seqSelectedConsignments, "seq");
  };

  const isAllConsignmentsSame = (consignments: any[], consignmentsIds: any[]) => {
    return consignments.length === consignmentsIds.length && consignments.every((v: any) => consignmentsIds.includes(v.code));
  };

  const getConsignmentRecord = () => {
    const consignments = getSeqSortedSelectedConsignments();
    if (!isUndefined(draftRoute?.route?.automatedRoute) && draftRoute?.route?.automatedRoute && draftRoute?.route?.consignmentsIds && draftRoute?.route?.consignmentsIds?.length > 0) {
      const consignmentsIds: any[] = draftRoute.route.consignmentsIds;
      if (isAllConsignmentsSame(consignments, consignmentsIds)) {
        let consignmentsOfObjects = consignmentsIds.map((item: string | number) => {
          return { code: item };
        });
        return consignmentsOfObjects;
      }
    } else if (consignments?.length === 0 && draftRoute?.route?.consignmentsIds?.length !== 0) {
      let consignmentsOfObjects = draftRoute.route.consignmentsIds.map((item: string | number) => {
        return { code: item };
      });
      return consignmentsOfObjects;
    }
    return consignments;
  };

  const createRoute = () => {
    const payload = createRouteCreationPayload({
      consignments: getConsignmentRecord(),
      route: isPlannedRoute ? selectedRoute.data : draftRoute.route,
      selectedDriver: isPlannedRoute ? routeDriverValue : driverValue,
      selectedHelper: isPlannedRoute ? routeHelperValue : helperValue,
      selectedVehicle: isPlannedRoute ? routeVehicleValue : vehicleValue,
      date: currentDate.startDate,
      transitTime: calculateTransitTime(mapRouteDirections),
      mapRouteDirections: mapRouteDirections,
      editMode: isPlannedRoute || false,
    });
    if (payload?.route?.deliveryArea.length === 0) {
      if (draftRoute?.route?.deliveryArea?.length > 0) {
        payload.route.deliveryArea = [...draftRoute.route.deliveryArea];
      } else if (location?.state?.route?.deliverySlot?.length > 0) {
        payload.route.deliverySlot = [...location.state.route.deliverySlot];
      }
    }
    if (payload?.route?.deliverySlot.length === 0) {
      if (draftRoute?.route?.deliverySlot?.length > 0) {
        payload.route.deliverySlot = [...draftRoute.route.deliverySlot];
      } else if (location?.state?.route?.deliverySlot?.length > 0) {
        payload.route.deliverySlot = [...location.state.route.deliverySlot];
      }
    }
    dispatch(createAndEditRoute({ payload: payload, editMode: isPlannedRoute || editMode || false }));
  };

  const capacityBreachDialogActionHandler = (type: string) => {
    if (type === "continue") {
      createRoute();
    }
    handleCapacityBreachDialogClose();
  };

  const getCapacityBreachDialogActions = () => {
    return (
      <Grid className={classes.capacityBreachDialogButtonsContainer} container>
        <Button
          className="dialogBtn secondary"
          variant="contained"
          onClick={() => {
            capacityBreachDialogActionHandler("cancel");
          }}
        >
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button
          className="dialogBtn primary"
          variant="contained"
          onClick={() => {
            capacityBreachDialogActionHandler("continue");
          }}
        >
          {AppConstants.BUTTONS.CONTINUE}
        </Button>
      </Grid>
    );
  };

  const getUpdateDialogDetailsContent = () => {
    return (
      <Grid className={classes.confirmDialogContentContainer} container>
        <Grid className="contentItem heading" item>
          <Typography>{`Are you sure you want to update ${draftRoute.route && draftRoute.route.routeId}?`}</Typography>
        </Grid>
        <Grid className="contentItem desc" item>
          <Typography>The changes made here will affect existing routes and cannot be undone.</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleUpdateDialogClose = () => {
    setOpenUpdateConfirmDialog(false);
  };

  const getCapacityBreachPopupMsg = (capacityBreach: any) => {
    let msg = "";
    if (capacityBreach.consignments) {
      msg = AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CONSIGNMENTS;
      if (capacityBreach.crates) {
        msg = AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CONSIGNMENTS_AND_CRATES;
        if (capacityBreach.transitTime) {
          msg = AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.ALL_VALUES;
        }
      } else {
        if (capacityBreach.transitTime) {
          msg = AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CONSIGNMENTS_AND_TRANSIT_TIME;
        }
      }
    } else if (capacityBreach.crates) {
      msg = AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CRATES;
      if (capacityBreach.transitTime) {
        msg = AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CRATES_AND_TRANSIT_TIME;
      }
    } else {
      if (capacityBreach.transitTime) {
        msg = AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.TRANSIT_TIME;
      }
    }
    return msg;
  };

  const handleCreateRoute = (isCreateClicked?: boolean) => {
    if (isCreateClicked) {
      setIsPlannedRoute(false);
    }
    const capacityBreach: any = {
      consignments: calculateCapacityBreach("consignments"),
      crates: calculateCapacityBreach("crates"),
      transitTime: calculateCapacityBreach("transitTime"),
    };
    if (capacityBreach.consignments || capacityBreach.crates || capacityBreach.transitTime) {
      setCapacityBreachPopupMsg(getCapacityBreachPopupMsg(capacityBreach));
      setOpenCapacityBreachConfirmDialog(true);
    } else {
      createRoute();
    }
  };

  const updateDialogActionHandler = (type: string) => {
    if (type === "update") {
      handleCreateRoute();
    }
    handleUpdateDialogClose();
  };

  const getUpdateDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogButtonsContainer} container>
        <Button
          className="dialogBtn secondary"
          variant="contained"
          onClick={() => {
            updateDialogActionHandler("cancel");
          }}
        >
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button
          className="dialogBtn primary"
          variant="contained"
          onClick={() => {
            updateDialogActionHandler("update");
          }}
        >
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const handleDiscardChanges = () => {
    setOpenDiscardConfirmDialog(true);
  };

  const handleUpdateRoute = () => {
    setIsPlannedRoute(false);
    setOpenUpdateConfirmDialog(true);
  };

  const handleUpdatePlannedRoute = () => {
    setIsPlannedRoute(true);
    setOpenUpdateConfirmDialog(true);
  };

  const getDriverMarker = async () => {
    const driverDetails = { name: "", location: { lat: 0, lng: 0 }, icon: TrcukFilledIcon, stopover: true };
    let driverId: string = "";
    if (location?.state?.route?.assignee?.id) {
      driverId = location?.state?.route?.assignee?.id;
    } else if (draftRoute?.route?.assignee?.id) {
      driverId = draftRoute?.route?.assignee?.id;
    }
    if (!isNull(driverId)) {
      const params: any = { driverIds: [driverId] };
      const { payload } = await dispatch(getDriverMarkerApi({ params: params }));
      if (payload && payload[driverId] && payload[driverId]?.latitude && payload[driverId]?.latitude !== 0) {
        driverDetails.name = driverId;
        driverDetails.location.lat = Number(payload[driverId]?.latitude);
        driverDetails.location.lng = Number(payload[driverId]?.longitude);
      }
      const driverMarker = [driverDetails];
      return driverMarker;
    }
    return [];
  };

  const getDocAddressMarkerDetails = () => {
    if (draftRoute?.route?.dockAddress?.latitude && draftRoute?.route?.dockAddress?.longitude) {
      return {
        name: draftRoute?.route?.dockAddress?.dockId || "",
        icon: transferPointIcon,
        location: { lat: parseFloat(draftRoute?.route?.dockAddress?.latitude || 0), lng: parseFloat(draftRoute?.route?.dockAddress?.longitude || 0) },
      };
    }
    if (location?.state?.route?.dockAddress?.latitude && location?.state?.route?.dockAddress?.longitude) {
      return {
        name: location?.state?.route?.dockAddress?.dockId || "",
        icon: transferPointIcon,
        location: { lat: parseFloat(location?.state?.route?.dockAddress?.latitude || 0), lng: parseFloat(location?.state?.route?.dockAddress?.longitude || 0) },
      };
    }
    return {};
  };

  const getAllSelectedConsignmentsMarkers = () => {
    const countryCodeValue = sessionStorage.getItem(AppConstants.COUNTRY_CODE) || countryCode;
    let selectedHub: any = hubData[countryCodeValue] ? hubData[countryCodeValue].filter((hub: any) => hub.code === location.state.hubCode) : [];
    selectedHub = selectedHub?.length > 0 ? selectedHub[0] : { code: location.state.hubCode, latitude: 0, longitude: 0 };
    const hubDetails = { name: selectedHub?.code || "", icon: mapHubIcon, location: { lat: parseFloat(selectedHub?.latitude || 0), lng: parseFloat(selectedHub?.longitude || 0) } };
    const markers = [hubDetails];

    const selectedSeqConsignments = getSeqSortedSelectedConsignments();
    const wayPoints = selectedSeqConsignments.map((consignmentItem: any) => {
      const location = consignmentItem?.data?.deliveryInformation
        ? {
            lat: parseFloat(consignmentItem?.data?.deliveryInformation?.customerContactInfo?.address?.latitude) || 0,
            lng: parseFloat(consignmentItem?.data?.deliveryInformation?.customerContactInfo?.address?.longitude) || 0,
          }
        : { lat: 0, lng: 0 };
      const label = consignmentsSequence[consignmentItem.code] ? `${consignmentsSequence[consignmentItem.code].seq}`.padStart(2, "0") : "";
      return { name: consignmentItem.code, location: location, icon: mapItemIcon, label: { text: label, fontSize: "10px", fontWeight: "600", color: COLORS.WHITE } };
    });
    const dockAddressDetails = getDocAddressMarkerDetails();
    if (dockAddressDetails?.name) {
      const dockAddressMarker = [dockAddressDetails];
      return [...markers, ...dockAddressMarker, ...wayPoints];
    }
    return [...markers, ...wayPoints];
  };

  const handleRefreshMap = async () => {
    const markers: any[] = getAllSelectedConsignmentsMarkers();
    const center = { lat: 0, lng: 0 };
    const latLngIndex = markers.findIndex((marker: any) => marker && marker.location && marker.location.lat !== 0);
    if (latLngIndex !== -1) {
      center.lat = markers[latLngIndex].location.lat;
      center.lng = markers[latLngIndex].location.lng;
    }
    let fetchDriverLocation: boolean = false;
    if (isRouteStatusOutForDelivery(location?.state?.route?.status)) {
      fetchDriverLocation = true;
    } else if (isRouteStatusOutForDelivery(draftRoute?.route?.status)) {
      fetchDriverLocation = true;
    }
    if (fetchDriverLocation) {
      const driverMarker = await getDriverMarker();
      setMapMarkers([...markers, ...driverMarker]);
      setMapCenterState(center);
      setCreateRouteState({ ...createRouteState, refreshInterval: AppConstants.PLANNING_CONSTANTS.DRIVER_LOCATION_REFRESH_INTERVAL });
    } else {
      setMapMarkers(markers);
      setMapCenterState(center);
    }
  };

  const millisToMinutesAndSeconds = (millis: number) => {
    const minutes: number = Math.floor(millis / 60000);
    const seconds: any = ((millis % 60000) / 1000).toFixed(0);
    let secondsValue: string = "";
    if (Number(seconds) < 10) {
      secondsValue = "0" + seconds.toString();
    } else {
      secondsValue = seconds.toString();
    }
    const obj = { minutes: minutes, seconds: secondsValue };
    return obj;
  };

  const refereshTimer = () => {
    let milliSecond = createRouteState.countDownInterval - AppConstants.PLANNING_CONSTANTS.REDUCE_DRIVER_LOCATION_COUNT_DOWN_INTERVAL;
    const timeValue = millisToMinutesAndSeconds(milliSecond);
    setCreateRouteState({ ...createRouteState, countDownInterval: milliSecond, minutes: timeValue.minutes, seconds: timeValue.seconds });
  };

  const refereshScreen = async () => {
    setCreateRouteState({ ...createRouteState, countDownInterval: AppConstants.PLANNING_CONSTANTS.DRIVER_LOCATION_REFRESH_INTERVAL });
    const driverMarker = await getDriverMarker();
    let driverId: string = "";
    if (location?.state?.route?.assignee?.id) {
      driverId = location?.state?.route?.assignee?.id;
    } else if (draftRoute?.route?.assignee?.id) {
      driverId = draftRoute?.route?.assignee?.id;
    }
    if (!isNull(driverId)) {
      let markersRecord: any[] = [...mapMarkers];
      let driverMarkerIndex = markersRecord.findIndex((marker: any) => marker?.name === driverId);
      if (driverMarkerIndex !== -1) {
        markersRecord.splice(driverMarkerIndex, 1);
        markersRecord[driverMarkerIndex] = driverMarker;
        setMapMarkers([...markersRecord, ...driverMarker]);
        setCreateRouteState({ ...createRouteState, refreshInterval: AppConstants.PLANNING_CONSTANTS.DRIVER_LOCATION_REFRESH_INTERVAL });
      }
    }
  };

  const handleOptimizeMapRoute = () => {
    setOptimizeMapRoute(true);
    handleRefreshMap();
  };

  const disableOptimizeMapRoute = () => {
    if (!isUndefined(draftRoute?.route?.automatedRoute) && !draftRoute?.route?.automatedRoute) {
      return getAllSelectedConsignmentsMarkers()?.length <= 1;
    }
    return true;
  };

  const changeDialogActionHandler = useCallback(
    (type: string) => {
      if (type === "proceed") {
        handleChangeRoute({ date: currentDate, statusValue: statusValue, slotValue: slotValue });
      } else {
        shouldBlockNavigationRef.current = true;
        setShouldBlockNavigation(true);
      }
      handleChangeDialogClose();
    },
    [handleChangeDialogClose, handleChangeRoute, currentDate, statusValue, slotValue]
  );

  const handleSnackbarExited = useCallback(() => {
    if (errorCode === AppConstants.PLANNING_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
      dispatch(userLogout());
    }
    if (error) {
      dispatch(resetPlanningError());
    }
    if (updatedRoute && updatedRoute.route) {
      dispatch(clearUpdatedRoute());
    }
    if (isAddressUpdated) {
      dispatch(resetAddressupdateStatus());
    }
  }, [dispatch, error, errorCode, updatedRoute, isAddressUpdated]);

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleReleaseSnackbarClose = () => {
    setOpenReleaseSnackbar(false);
  };

  const getSelectedAreas = useCallback(
    (forTooltip?: boolean) => {
      const selectedAreas: string[] = getUniqueValues(getAllSelectedConsignments(), "area");
      let returnValue: any = selectedAreas?.length > 0 ? selectedAreas.slice() : [];
      if (!forTooltip) {
        returnValue = selectedAreas?.length > 0 ? (selectedAreas.length > 1 ? `Multiple(${selectedAreas.length})` : selectedAreas[0]) : "";
      }
      return returnValue;
    },
    [getAllSelectedConsignments]
  );

  const getSelectedAreasTooltip = useCallback(() => {
    const selectedAreas: any = getSelectedAreas(true);
    return (
      selectedAreas?.length > 0 &&
      selectedAreas.map((area: string, index: number) => (
        <Typography key={`tooltipArea-${area}-${index}`} className="tooltipItem">
          {area}
        </Typography>
      ))
    );
  }, [getSelectedAreas]);

  /** https://mafretail.atlassian.net/browse/MILE-3314 */
  const getDraftSelectedAreas = (forTooltip?: boolean) => {
    const selectedAreas: string[] = draftRoute?.route?.deliveryArea;
    let returnValue: any = selectedAreas?.length > 0 ? selectedAreas.slice() : [];
    if (!forTooltip) {
      returnValue = selectedAreas?.length > 0 ? (selectedAreas.length > 1 ? `Multiple(${selectedAreas.length})` : selectedAreas[0]) : "";
    }
    return returnValue;
  };

  /** https://mafretail.atlassian.net/browse/MILE-3314 */
  const getDraftSelectedAreasTooltip = () => {
    const selectedAreas: any = getDraftSelectedAreas(true);
    return (
      selectedAreas.length &&
      selectedAreas?.map((area: string, index: number) => (
        <Typography key={`tooltipArea-${area}-${index}`} className="tooltipItem">
          {area}
        </Typography>
      ))
    );
  };

  const getSelectedDeliverySlots = useCallback(
    (forTooltip?: boolean) => {
      const selectedSlots: string[] = getUniqueValues(getAllSelectedConsignments(), "slot");
      let returnValue: any = selectedSlots?.length > 0 ? selectedSlots.slice() : [];
      if (!forTooltip) {
        if (selectedSlots?.length > 0) {
          const firstDelSlot: any = selectedSlots?.length && selectedSlots[0].split("-");
          const slotValue = getSlotUIValue(firstDelSlot);
          returnValue = selectedSlots?.length > 1 ? `Multiple(${selectedSlots.length})` : `${slotValue.startTime} - ${slotValue.endTime}`;
        } else {
          returnValue = "";
        }
      }
      return returnValue;
    },
    [getAllSelectedConsignments]
  );

  const getSelectedSlotsTooltip = useCallback(() => {
    const selectedSlots: any = getSelectedDeliverySlots(true);
    return (
      selectedSlots.length &&
      selectedSlots.map((slot: string, index: number) => {
        const delSlot = slot.split("-");
        const slotValue = getSlotUIValue(delSlot);
        return <Typography key={`tooltipArea-${slot}-${index}`} className="tooltipItem">{`${slotValue.startTime} - ${slotValue.endTime}`}</Typography>;
      })
    );
  }, [getSelectedDeliverySlots]);

  /** https://mafretail.atlassian.net/browse/MILE-3314 */
  const getDraftSelectedDeliverySlots = (forTooltip?: boolean) => {
    const selectedSlots: string[] = draftRoute?.route?.deliverySlot;
    let returnValue: any = selectedSlots?.length > 0 ? selectedSlots.slice() : [];
    if (!forTooltip) {
      if (selectedSlots?.length) {
        const firstDelSlot: any = selectedSlots?.length > 0 && selectedSlots[0].split("-");
        const slotValue = getSlotUIValue(firstDelSlot);
        returnValue = selectedSlots.length > 1 ? `Multiple(${selectedSlots.length})` : `${slotValue.startTime} - ${slotValue.endTime}`;
      } else {
        returnValue = "";
      }
    }
    return returnValue;
  };

  /** https://mafretail.atlassian.net/browse/MILE-3314 */
  const getDraftSelectedSlotsTooltip = () => {
    const selectedSlots: any = getDraftSelectedDeliverySlots(true);
    return (
      selectedSlots.length > 0 &&
      selectedSlots.map((slot: string, index: number) => {
        const delSlot = slot.split("-");
        const slotValue = getSlotUIValue(delSlot);
        return <Typography key={`tooltipArea-${slot}-${index}`} className="tooltipItem">{`${slotValue.startTime} - ${slotValue.endTime}`}</Typography>;
      })
    );
  };

  const getCurrentSelectedDate = () => {
    return moment(currentDate.startDate, AppConstants.DATE_FORMAT).format(AppConstants.DATE_FORMAT);
  };

  const checkEditRouteExtraConsignments = () => {
    let isEqual = false;
    if (draftRoute.route) {
      isEqual = isEqualArrays(
        draftRoute.route.consignmentsIds,
        getAllSelectedConsignments().map((item: any) => item.code)
      );
    }
    return isEqual;
  };

  const disableCreateRt = () => {
    if (draftRoute?.route?.routeType === ROUTE_TYPE.PARENT_ROUTE || draftRoute?.route?.routeType === ROUTE_TYPE.SUB_ROUTE) {
      return draftRoute?.route?.consignmentsIds?.length === 0;
    }
    return !getAllSelectedConsignments().length;
  };

  const disableEditRt = () => {
    let disable = true;
    if (draftRoute.route) {
      const currentHelper = draftRoute.route.helper ? draftRoute.route.helper.id : AppConstants.SELECT_NONE.value;
      const currentDriver = draftRoute.route.assignee ? draftRoute.route.assignee.id : AppConstants.SELECT_NONE.value;
      const currentVehicle = draftRoute.route.assignedVehicle ? draftRoute.route.assignedVehicle.id : AppConstants.SELECT_NONE.value;
      if (draftRoute?.route?.routeType === ROUTE_TYPE.PARENT_ROUTE || draftRoute?.route?.routeType === ROUTE_TYPE.SUB_ROUTE) {
        if (helperValue !== currentHelper || driverValue !== currentDriver || vehicleValue !== currentVehicle) {
          disable = false;
        }
      } else if (!checkEditRouteExtraConsignments() || helperValue !== currentHelper || driverValue !== currentDriver || vehicleValue !== currentVehicle) {
        disable = false;
      }
      return disable;
    }
    return true;
  };

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    (e || window.event).returnValue = "";
    if (editMode) {
      handleDiscardRoute(true);
    }
    return "";
  };

  const handleOrderStatusOnUnload = (status: string, reset?: boolean) => {
    let consignments: any = sessionStorage.getItem("extraConsignments");
    consignments = consignments ? JSON.parse(consignments) : [];
    if (consignments.length) {
      updateOrderStatus({
        consignments: consignments,
        status: status,
        routeId: sessionStorage.getItem("routeId"),
      });
    }
    if (reset) {
      sessionStorage.setItem("routeId", "");
      sessionStorage.setItem("extraConsignments", "[]");
    }
  };

  const onConsignmentsDragStart = () => {
    setDraggingItem(true);
  };

  const onConsignmentsDragEnd = useCallback(
    (result: any) => {
      setDraggingItem(false);
      const { destination, source } = result;
      if (!destination) {
        return;
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }
      let updatedConsignments = getSeqSortedSelectedConsignments();
      const [deletedItem] = updatedConsignments.splice(source.index, 1);
      updatedConsignments.splice(destination.index, 0, deletedItem);
      updatedConsignments = updatedConsignments.map((item: any) => item.code);
      const seqParams = addSequence(updatedConsignments, "PLANNED", {
        allSequencedCons: {},
        lastSeqNo: 0,
      });
      setConsignmentsSequence(seqParams.allSequencedCons);
      setLastSequenceNumber(seqParams.lastSeqNo);
      setUpdateMap(false);
      setOptimizeMapRoute(false);
    },
    [addSequence, getSeqSortedSelectedConsignments]
  );

  const getCenterOfMarkers = (markers: any[]) => {
    const latLngIndex = markers.findIndex((marker: any) => marker && marker.location && marker.location.lat !== 0);
    if (latLngIndex !== -1) {
      return { lat: markers[latLngIndex].location.lat, lng: markers[latLngIndex].location.lng };
    }
    return { lat: 0, lng: 0 };
  };

  const getMapCenter = () => {
    const markers: any[] = getAllSelectedConsignmentsMarkers();
    if (markers?.length > 0) {
      const center = getCenterOfMarkers(markers);
      return center;
    }
    return { lat: 0, lng: 0 };
  };

  const toggleAllDropdowns = useCallback(() => {
    const isExpanded = !toggleAllDropdownExpanded;
    const allAreasAndSlots: string[] = getAllAreasAndSlots(areawiseConsignments);
    if (isExpanded) {
      setExpandedConsignments(allAreasAndSlots, consignmentSearchValue ? true : false);
    } else {
      setExpandedConsignments([], consignmentSearchValue ? true : false);
    }
    setToggleAllDropdownExpanded(isExpanded);
  }, [toggleAllDropdownExpanded, areawiseConsignments, consignmentSearchValue, setExpandedConsignments]);

  const getCurrentAreaConsignments = useCallback(
    (areaCode: string) => {
      return allConsignments.filter((item: any) => item.area === areaCode);
    },
    [allConsignments]
  );

  const getAreaTotalCrates = useCallback(
    (area: any): string => {
      const allAreaConsignments = getCurrentAreaConsignments(area.code);
      return `${getTotalCrates(allAreaConsignments)}`;
    },
    [getCurrentAreaConsignments]
  );

  const getAreaTotalConsignments = useCallback(
    (area: any): string => {
      const allAreaConsignments = getCurrentAreaConsignments(area.code);
      return `${allAreaConsignments.length}`;
    },
    [getCurrentAreaConsignments]
  );

  const getTransitTime = useCallback(() => {
    let transitTimeSecs = calculateTransitTime(mapRouteDirections);
    return convertTime(transitTimeSecs);
  }, [mapRouteDirections]);

  const getUpdatedDdnList = useCallback(
    (type: string, route: any) => {
      let updatedList: SelectItemProps[] = [];
      switch (type) {
        case "driver":
          updatedList = [...driversList];
          if (route && route.assignee) {
            updatedList = [...updatedList, { name: route.assignee.name, value: route.assignee.id }];
          }
          break;
        case "helper":
          updatedList = [...helpersList];
          if (route && route.helper) {
            updatedList = [...updatedList, { name: route.helper.name, value: route.helper.id }];
          }
          break;
        case "vehicle":
          updatedList = [...vehiclesList];
          if (updatedList.length === 1 && !singleEntryVehical) {
            singleEntryVehical = true;
            setVehicleValue(updatedList[0]?.value);
          }
          if (route && route.assignedVehicle && updatedList.findIndex((vehicle: SelectItemProps) => vehicle.value === route.assignedVehicle.id) === -1) {
            updatedList = [...updatedList, { name: route.assignedVehicle.name, value: route.assignedVehicle.id }];
          }
          break;
        default:
          break;
      }
      return updatedList;
    },
    [driversList, helpersList, vehiclesList]
  );

  const handleClearCurrentSlot = () => {
    dispatch(clearCurrentSlot());
  };

  const handleEditRoute = () => {
    handleClearCurrentSlot();
    setEditRoute(location.state.route || null);
  };

  const checkUnselectVisibility = () => {
    return editMode ? (draftRoute.route ? (draftRoute.route.displayRouteStatus !== "PLANNED" ? false : true) : true) : true;
  };

  const getStepperTimelineItems = () => {
    return getSeqSortedSelectedConsignments().map((consignmentItem: any, index: number) => (
      <Step key={`timeline-step-${consignmentItem.code}`} className="timelineStep">
        <Draggable draggableId={consignmentItem.code} index={index}>
          {(provided) => (
            <StepLabel {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="stepLabel" StepIconComponent={activeTimelineDot}>
              {`${index + 1}`.padStart(2, "0")}
            </StepLabel>
          )}
        </Draggable>
      </Step>
    ));
  };

  const getListViewTimelineItems = () => {
    return getSeqSortedSelectedConsignments().map((consignmentItem: any, index: number) => (
      <TimelineItem key={`listview-timeline-item-${consignmentItem.code}`} className="timelineItem">
        <TimelineSeparator>
          <TimelineDot className="timelineDot listItem">
            <Grid className="countIcon">
              <Typography className="count">{`${index + 1}`.padStart(2, "0")}</Typography>
            </Grid>
          </TimelineDot>
          <TimelineConnector className="primaryConnector" />
        </TimelineSeparator>
        <TimelineContent className="timelineContent">
          <Grid className="contentContainer" container>
            <Grid className="listViewContentItem itemHeading" onClick={() => handleConsignmentDetailsClick(consignmentItem.data)} item>
              <Typography className="id">{consignmentItem.code}</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid className="listViewContentItem itemDetails" item>
              <Grid className="itemDetailsContainer" container>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                    <Typography className="count">
                      {`${consignmentItem.data && consignmentItem.data.deliveryInformation && consignmentItem.data.deliveryInformation.totalCratesCount}`.padStart(2, "0")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={walletIcon} />
                    <Typography className="count">{consignmentItem.data && consignmentItem.data.metadata && consignmentItem.data.metadata.paymentType}</Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={mobileIcon} />
                    <Typography className="count">
                      {consignmentItem.data && consignmentItem.data.deliveryInformation && consignmentItem.data.deliveryInformation.customerContactInfo
                        ? consignmentItem.data.deliveryInformation.customerContactInfo.phone
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={timeSlotIcon} />
                    <Typography className="count">{consignmentItem.slotValue}</Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={locationIcon} />
                    <Typography className="count address">
                      {getAddressInfo(consignmentItem.data && consignmentItem.data.deliveryInformation ? consignmentItem.data.deliveryInformation.customerContactInfo.address : "")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TimelineContent>
      </TimelineItem>
    ));
  };

  const getListViewSubRoute = (data: any) => {
    return data.map((consignmentItem: any, index: number) => (
      <TimelineItem key={`sub-route-listview-timeline-item-${consignmentItem?.consignmentCode}`} className="timelineItem">
        <TimelineSeparator>
          <TimelineDot className="timelineDot listItem">
            <Grid className="countIcon">
              <Typography className="count">{`${index + 1}`.padStart(2, "0")}</Typography>
            </Grid>
          </TimelineDot>
          <TimelineConnector className="primaryConnector" />
        </TimelineSeparator>
        <TimelineContent className="timelineContent">
          <Grid className="contentContainer" container>
            <Grid className="listViewContentItem itemHeading" onClick={() => handleConsignmentDetailsClick(consignmentItem)} item>
              <Typography className="id">{consignmentItem?.consignmentCode}</Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid className="listViewContentItem itemDetails" item>
              <Grid className="itemDetailsContainer" container>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                    <Typography className="count">{consignmentItem?.deliveryInformation?.totalCratesCount}</Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={walletIcon} />
                    <Typography className="count">{consignmentItem?.metadata?.paymentType}</Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={mobileIcon} />
                    <Typography className="count">{consignmentItem?.deliveryInformation?.customerContactInfo?.phone}</Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={timeSlotIcon} />
                    <Typography className="count">{consignmentItem?.slotValue}</Typography>
                  </Grid>
                </Grid>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <SvgIcon className="icon" viewBox="0 0 16 16" component={locationIcon} />
                    <Typography className="count address">{consignmentItem?.deliveryInformation?.customerContactInfo?.address}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TimelineContent>
      </TimelineItem>
    ));
  };

  const getListViewSubRouteTimelineItems = () => {
    return (
      subRoutes.length > 0 &&
      subRoutes.map((consignmentItem: any) => (
        <Timeline className={classes.listViewTimeline}>
          <TimelineItem className="timelineItem first">
            <TimelineSeparator>
              <TimelineDot className="timelineDot">
                <SvgIcon className="timelineIcon areaIcon" component={areaTimelineIcon} viewBox="0 0 32 32" />
              </TimelineDot>
              <TimelineConnector className="primaryConnector" />
            </TimelineSeparator>
            <TimelineContent className="timelineContent first">
              <Typography className="titleHeading">{consignmentItem.routeId}</Typography>
            </TimelineContent>
          </TimelineItem>
          {consignmentItem?.orders?.length > 0 && getListViewSubRoute(consignmentItem.orders)}
        </Timeline>
      ))
    );
  };

  const getCustomSubRouteTimelineItems = () => {
    return routeConsignments?.length > 0 ? (
      <Timeline className={classes.listViewTimeline}>
        {routeConsignments.map((consignmentItem: any, index: number) => (
          <TimelineItem key={`sub-route-listview-timeline-item-${consignmentItem?.consignmentCode}`} className="timelineItem">
            <TimelineSeparator>
              <TimelineDot className="timelineDot listItem">
                <Grid className="countIcon">
                  <Typography className="count">{`${index + 1}`.padStart(2, "0")}</Typography>
                </Grid>
              </TimelineDot>
              <TimelineConnector className="primaryConnector" />
            </TimelineSeparator>
            <TimelineContent className="timelineContent">
              <Grid className="contentContainer" container>
                <Grid className="listViewContentItem itemHeading" onClick={() => handleConsignmentDetailsClick(consignmentItem)} item>
                  <Typography className="id">{consignmentItem?.consignmentCode}</Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid className="listViewContentItem itemDetails" item>
                  <Grid className="itemDetailsContainer" container>
                    <Grid className="detailsItem" item>
                      <Grid className="itemContainer" container>
                        <SvgIcon className="icon" viewBox="0 0 16 16" component={cratesIcon} />
                        <Typography className="count">{consignmentItem?.deliveryInformation?.totalCratesCount}</Typography>
                      </Grid>
                    </Grid>
                    <Grid className="detailsItem" item>
                      <Grid className="itemContainer" container>
                        <SvgIcon className="icon" viewBox="0 0 16 16" component={walletIcon} />
                        <Typography className="count">{consignmentItem?.metadata?.paymentType}</Typography>
                      </Grid>
                    </Grid>
                    <Grid className="detailsItem" item>
                      <Grid className="itemContainer" container>
                        <SvgIcon className="icon" viewBox="0 0 16 16" component={mobileIcon} />
                        <Typography className="count">{consignmentItem?.deliveryInformation?.customerContactInfo?.phone}</Typography>
                      </Grid>
                    </Grid>
                    <Grid className="detailsItem" item>
                      <Grid className="itemContainer" container>
                        <SvgIcon className="icon" viewBox="0 0 16 16" component={timeSlotIcon} />
                        <Typography className="count">{consignmentItem?.slotValue}</Typography>
                      </Grid>
                    </Grid>
                    <Grid className="detailsItem" item>
                      <Grid className="itemContainer" container>
                        <SvgIcon className="icon" viewBox="0 0 16 16" component={locationIcon} />
                        <Typography className="count address">
                          {consignmentItem?.deliveryInformation?.customerContactInfo?.address ? getAddressInfo(consignmentItem?.deliveryInformation?.customerContactInfo?.address) : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    ) : (
      <></>
    );
  };

  const handleChange = (code: any) => {
    setSelectedCode(code);
    setAddressVerified(true);
    setOpenAddressPopup(true);
  };

  const handleAddressDialogClose = () => {
    setOpenAddressPopup(false);
  };

  const getAddressDialogTitleContent = () => {
    return <Grid className={classes.titleContainer} container></Grid>;
  };

  const getAddressDialogDetailsContent = () => {
    return (
      <Grid container>
        <Grid className="contentItem heading" item>
          <Typography className={classes.addressContent}>Do you want to mark this address unverified?</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleMarkAddressVerified = (consignment: any, address: boolean) => {
    const input = { consignmentCode: consignment };
    const payload = createPayloadToMarkAddressUnverified(input, address);
    dispatch(getAddressStatus({ params: payload }));
    setOpenAddressPopup(false);
  };

  const updateAddressDialogActionHandler = (type: string, order?: string, address?: any) => {
    if (type === "yes") {
      setAddressVerified(true);
      handleMarkAddressVerified(order, true);
    } else {
      setOpenAddressPopup(false);
      setAddressVerified(false);
    }
  };

  const getAddressDialogActions = () => {
    return (
      <Grid className={classes.confirmDialogAddressButtonsContainer} container>
        <Button
          className="dialogBtn secondary"
          variant="contained"
          onClick={() => {
            updateAddressDialogActionHandler("no");
          }}
        >
          {AppConstants.BUTTONS.NO}
        </Button>
        <Button
          className="dialogBtn primary"
          variant="contained"
          onClick={() => {
            updateAddressDialogActionHandler("yes", selectedCode, addressVerified);
          }}
        >
          {AppConstants.BUTTONS.YES}
        </Button>
      </Grid>
    );
  };

  const handleRouteAccordianToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string) => {
    event.stopPropagation();
    setSelectedRoute({});
    if (routeName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleRouteAccordianClickedUtils(allRouteState, routeName, vehiclesList);
      const routeSelected = newAllRouteState.selectedRoute;
      if (isNonEmptyObject(routeSelected)) {
        const obj = { code: routeSelected.id, value: routeSelected.routeId, data: routeSelected };
        handleRouteSelection(obj);
      }
      setAllRouteState(newAllRouteState);
    }
  };

  const handleRoutAreaAccordianToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, subRouteAreaName?: string) => {
    event.stopPropagation();
    setSelectedRoute({});
    if (routeName && routeDataName && subRouteAreaName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleRoutAreaSubareaAccordianToggleUtils(allRouteState, routeName, routeDataName, subRouteAreaName);
      setAllRouteState(newAllRouteState);
    } else if (routeName && routeDataName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleRoutAreaAccordianToggleUtils(allRouteState, routeName, routeDataName, vehiclesList);
      const subRouteSelected = newAllRouteState.selectedSubRoute;
      if (isNonEmptyObject(subRouteSelected)) {
        const obj = { code: subRouteSelected.id, value: subRouteSelected.routeId, data: subRouteSelected };
        handleRouteSelection(obj);
      } else {
        const route = newAllRouteState.selectedRoute;
        if (isNonEmptyObject(route)) {
          const obj = { code: route.id, value: route.routeId, data: route };
          handleRouteSelection(obj);
        }
      }
      setAllRouteState(newAllRouteState);
    }
  };

  const handleRouteSlotAccordianToggle = (event: React.ChangeEvent<HTMLInputElement>, routeName: string, routeDataName: string, slotName: string, subRouteAreaName?: string) => {
    event.stopPropagation();
    if (routeName && routeDataName && slotName && subRouteAreaName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleSubRouteSlotAccordianToggleUtils(allRouteState, routeName, routeDataName, slotName, subRouteAreaName);
      setAllRouteState(newAllRouteState);
    } else if (routeName && routeDataName && slotName) {
      setAllRouteState({ ...allRouteState, loading: true });
      let newAllRouteState = handleRouteSlotAccordianToggleUtils(allRouteState, routeName, routeDataName, slotName);
      setAllRouteState(newAllRouteState);
    }
  };

  const handleRouteOrderAccordianToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    routeName: string,
    routeDataName: string,
    slotName: string,
    orderObjIndex: number,
    subRouteAreaName?: string
  ) => {
    event.stopPropagation();
    if (routeName && routeDataName && subRouteAreaName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
      let newAllRouteState = handleSubrouteOrderAccordianToggleUtils(allRouteState, routeName, routeDataName, slotName, orderObjIndex, subRouteAreaName);
      setAllRouteState(newAllRouteState);
    } else if (routeName && routeDataName && slotName && !isUndefined(orderObjIndex) && Number(orderObjIndex) >= 0) {
      let newAllRouteState = handleOrderAccordianToggleUtils(allRouteState, routeName, routeDataName, slotName, orderObjIndex);
      setAllRouteState(newAllRouteState);
    }
  };

  const plannedConsignmentPresent = () => {
    if (
      countObjectProperties(plannedRoutesNew) > 0 &&
      allRouteState &&
      allRouteState.plannedConsignmentsData &&
      isObject(allRouteState.plannedConsignmentsData) &&
      isNonEmptyObject(allRouteState.plannedConsignmentsData)
    ) {
      return true;
    }
    return false;
  };

  const getPllanedAreawiseConsignments = () => {
    return plannedConsignmentPresent() ? (
      <>
        {Object.keys(allRouteState.plannedConsignmentsData).map((routeName: any) => (
          <CustomPlannedRouteView
            classes={classes2}
            routeObj={allRouteState.plannedConsignmentsData[routeName]}
            routeName={routeName}
            handleRouteAccordianToggle={handleRouteAccordianToggle}
            handleRoutAreaAccordianToggle={handleRoutAreaAccordianToggle}
            handleRouteSlotAccordianToggle={handleRouteSlotAccordianToggle}
            handleRouteOrderAccordianToggle={handleRouteOrderAccordianToggle}
            viewMode={true}
          />
        ))}
      </>
    ) : (
      <Typography className="padding-2">No Items to display</Typography>
    );
  };

  /**
   *
   * Dock Address
   *
   */

  const isRouteTypeMatches = (routeType: string) => {
    let currectRouteType: string = "";
    if (draftRoute?.route?.routeType) {
      currectRouteType = draftRoute?.route?.routeType;
    } else if (location?.state?.parentRoute) {
      currectRouteType = location.state.parentRoute;
    }
    return currectRouteType === routeType;
  };

  const getDockAddressObject = () => {
    let dockAddressObject = {};
    if (draftRoute?.route?.dockAddress?.dockId) {
      dockAddressObject = draftRoute?.route?.dockAddress;
    } else if (location?.state?.route?.dockAddress?.dockId) {
      dockAddressObject = location.state.route.dockAddress;
    }
    return dockAddressObject;
  };

  const generateDocaddress = () => {
    let docAddress = "";
    let dockAddressObject: any = getDockAddressObject();

    if (dockAddressObject && isNonEmptyObject(dockAddressObject)) {
      if (dockAddressObject?.dockId) {
        docAddress = ` ${dockAddressObject.dockId} -`;
      }
      if (dockAddressObject?.dockName) {
        docAddress = docAddress + ` ${dockAddressObject.dockName} -`;
      }
      if (dockAddressObject?.streetName) {
        docAddress = docAddress + ` ${dockAddressObject.streetName}`;
      }
      if (dockAddressObject?.building) {
        docAddress = docAddress + ` ${dockAddressObject.building}`;
      }
    }
    return docAddress;
  };

  const showDockAddressTimeLine = () => {
    return (
      <TimelineItem key={`docAddress-listview-timeline-item`} className="timelineItem">
        <TimelineSeparator>
          <TimelineDot className="timelineDot listItem">
            <Grid className="countIcon">
              <Typography className="count">{``}</Typography>
            </Grid>
          </TimelineDot>
          <TimelineConnector className="primaryConnector" />
        </TimelineSeparator>
        <TimelineContent className="timelineContent">
          <Grid className="contentContainer" container>
            <Grid className="listViewContentItem itemSubHeading" item>
              <Grid className={`itemContainer ${classes.flexWrapNoWrap}`} container>
                <SvgIcon className="icon" viewBox="0 0 16 16" component={locationIcon} />
                <Typography className="subHeading">{`Transfer Point`}</Typography>
              </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid className="listViewContentItem itemDetails" item>
              <Grid className="itemDetailsContainer" container>
                <Grid className="detailsItem" item>
                  <Grid className="itemContainer" container>
                    <Typography className="count address">{`${generateDocaddress() || ""}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TimelineContent>
      </TimelineItem>
    );
  };

  /**
   *
   * Use Effect
   *
   */

  useEffect(() => {
    if (countObjectProperties(plannedRoutesNew) > 0) {
      plannedRoutesNew && designAllRoutesStateObject();
    }
  }, [plannedRoutesNew]);

  useEffect(() => {
    if (createRouteState.refreshInterval > 0) {
      setCreateRouteState({ ...createRouteState, countDownInterval: createRouteState.refreshInterval });
      const timeInterval = setInterval(refereshScreen, createRouteState.refreshInterval);
      return () => clearInterval(timeInterval);
    } else {
      setCreateRouteState({ ...createRouteState, countDownInterval: 0 });
    }
  }, [createRouteState.refreshInterval]);

  useEffect(() => {
    if (createRouteState.countDownInterval > 0) {
      const screenTimerInterval = setInterval(refereshTimer, 1000);
      return () => clearInterval(screenTimerInterval);
    }
  }, [createRouteState.countDownInterval]);

  useEffect(() => {
    setConsignmentData(order);
  }, [order]);

  useEffect(() => {
    if (editRoute) {
      if (updatedRoute || routeDiscardSuccess) {
        dispatch(clearUpdatedRoute());
      } else {
        onLoadRef.current = true;
        filterChangeRef.current = false;
        history.push(`${AppConstants.ROUTES.EDIT_ROUTE}/${editRoute.id}`, {
          plannedDate: editRoute.plannedDate,
          hubCode: editRoute.hubCode,
          hubName: editRoute.hubName,
          route: editRoute,
          slots: editRoute.deliverySlot || [],
          isExpress: isExpressFlow,
          redirect: {
            route: `${AppConstants.ROUTES.VIEW_ROUTE}/${editRoute.id}`,
            state: {
              plannedDate: editRoute.plannedDate,
              hubCode: editRoute.hubCode,
              hubName: editRoute.hubName,
              slots: editRoute.deliverySlot || [],
              route: editRoute,
              parentRoute: editRoute?.routeType,
            },
          },
        });
      }
    }
  }, [editRoute, isExpressFlow]);

  useEffect(() => {
    if (mapMarkers.length) {
      setUpdateMap(true);
    } else {
      setUpdateMap(false);
    }
  }, [mapMarkers]);

  useEffect(() => {
    if (optimizeMapRoute && mapRouteDirections) {
      const wayPointsOrder = mapRouteDirections.waypoint_order;
      const selectedWaypoints = getSeqSortedSelectedConsignments();
      if (wayPointsOrder && wayPointsOrder.length && selectedWaypoints.length) {
        let newSequence = _.cloneDeep(consignmentsSequence);
        selectedWaypoints.forEach((wp: any, index: number) => {
          newSequence[wp.code].seq = wayPointsOrder[index] + 1;
        });
        setConsignmentsSequence(newSequence);
      }
    }
  }, [mapRouteDirections]);

  useEffect(() => {
    if (autoRefreshMap) {
      handleRefreshMap();
    }
  }, [autoRefreshMap]);

  useEffect(() => {
    if (autoRefreshMapRef.current) {
      setAutoRefreshMap(true);
    }
  }, [selectedConsignments]);

  useEffect(() => {
    if (error) {
      openSnackbarPopup(error, AppConstants.SNACKBAR.TYPES.ERROR);
      if (errorCode === AppConstants.PLANNING_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.CONCURRENT_CONSIGNMENT) {
        shouldBlockNavigationRef.current = false;
        sessionStorage.setItem("extraConsignments", "[]");
        setShouldBlockNavigation(false);
      }
    }
  }, [error, errorCode]);

  useEffect(() => {
    if (allConsignments.length) {
      let newChecked: any = {};
      let newSelected: any = {};
      let extraConsignments: any = [];
      let checkedConsForSeq: string[] = [];
      let expandedConsignments = [...consignmentExpanded];
      allConsignments.forEach((item: any) => {
        if (newChecked[item.area]) {
          if (!newChecked[item.area][item.slot]) {
            newChecked[item.area][item.slot] = [];
          }
        } else {
          newChecked[item.area] = { [item.slot]: [] };
        }
        if (newSelected[item.area]) {
          if (!newSelected[item.area][item.slot]) {
            newSelected[item.area][item.slot] = [];
          }
        } else {
          newSelected[item.area] = { [item.slot]: [] };
        }
        if (item.status === "PLANNED") {
          newChecked[item.area][item.slot].push(item.code);
          checkedConsForSeq.push(item.code);
          newSelected[item.area][item.slot].push(item);
          if (!(editMode || viewMode)) {
            extraConsignments.push(item.code);
          }
          expandedConsignments.push(item.area);
          expandedConsignments.push(`${item.area}-${item.slot}`);
        }
      });
      const seqParams: any = addSequence(checkedConsForSeq, "PLANNED", { allSequencedCons: _.cloneDeep(consignmentsSequence), lastSeqNo: lastSequenceNumber });
      setConsignmentsSequence(seqParams.allSequencedCons);
      setLastSequenceNumber(seqParams.lastSeqNo);
      setCheckedConsignments(newChecked);
      setSelectedConsignments(newSelected);
      setUnplannedConsignmentsData({ ...unplannedConsignmentsData, selectedConsignments: newSelected });
      setExpandedConsignments(expandedConsignments);
      if (editMode && draftRoute.route) {
        if (draftRoute.route.assignee) {
          setDriverValue(draftRoute.route.assignee.id);
        } else {
          setDriverValue(AppConstants.SELECT_NONE.value);
        }
        if (draftRoute.route.helper) {
          setHelperValue(draftRoute.route.helper.id);
        } else {
          setHelperValue(AppConstants.SELECT_NONE.value);
        }
        if (draftRoute.route.assignedVehicle) {
          setVehicleValue(draftRoute.route.assignedVehicle.id);
        } else {
          setVehicleValue(AppConstants.SELECT_NONE.value);
        }
      }
      sessionStorage.setItem("extraConsignments", JSON.stringify(extraConsignments));
      autoRefreshMapRef.current = true;
      setOptimizeMapRoute(false);
    } else {
      setConsignmentsSequence({});
      setLastSequenceNumber(0);
      setCheckedConsignments({});
      setSelectedConsignments({});
      setExpandedConsignments([], true);
    }
  }, [allConsignments]);

  useEffect(() => {
    if (editMode) {
      if (checkEditRouteExtraConsignments()) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
      } else {
        shouldBlockNavigationRef.current = true;
        setShouldBlockNavigation(true);
      }
    } else {
      if (getAllSelectedConsignments().length) {
        shouldBlockNavigationRef.current = true;
        setShouldBlockNavigation(true);
      } else {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
      }
    }
  }, [checkedConsignments]);

  useEffect(() => {
    let newConsignments = _.cloneDeep(slotwiseConsignments);
    Object.keys(newConsignments).forEach((areaKey: string) => {
      Object.keys(newConsignments[areaKey]).forEach((slotKey: string) => {
        if (newConsignments[areaKey]) {
          newConsignments[areaKey][slotKey] = [AppConstants.SELECT_ALL, ...newConsignments[areaKey][slotKey]];
        }
      });
    });
    setSelectableConsignments(newConsignments);
  }, [slotwiseConsignments]);

  useEffect(() => {
    if (currentSlot) {
      let currentSlotValue: any = slotValue;
      if (editMode) {
        if (currentSlot && currentSlot.code) {
          if (onLoadRef.current) {
            currentSlotValue = [currentSlot.code];
          }
        }
        handleCreateDraftRoute({ slots: currentSlotValue, statusValue: statusValue });
      } else {
        if (currentSlot && currentSlot.code) {
          if (onLoadRef.current) {
            currentSlotValue = [currentSlot.code];
          }
          handleCreateDraftRoute({ slots: currentSlotValue, statusValue: statusValue });
        } else {
          currentSlotValue = [AppConstants.SELECT_NONE.value];
          handleOrderStatusOnUnload("UNPLANNED", true);
        }
      }
      setSlotValue(currentSlotValue);
    }
  }, [currentSlot]);

  useEffect(() => {
    if (draftRoute.route) {
      updateConsignments();
    } else {
      updateAllConsignments({ areaWiseConsignments: [], slotwiseConsignments: {}, allConsignments: [], loading: loading });
    }
  }, [draftRoute]);

  useEffect(() => {
    if (routeCreateSuccess) {
      openSnackbarPopup(`${draftRoute.route && draftRoute.route.routeId} ${AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CREATE_ROUTE_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
      shouldBlockNavigationRef.current = false;
      setShouldBlockNavigation(false);
    }
  }, [routeCreateSuccess]);

  useEffect(() => {
    if (updatedRoute && updatedRoute.route) {
      openSnackbarPopup(`Route "${updatedRoute.route.routeId}" ${AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.UPDATE_ROUTE_SUCCESS_MSG}`, AppConstants.SNACKBAR.TYPES.SUCCESS);
      if (!isPlannedRoute) {
        shouldBlockNavigationRef.current = false;
        setShouldBlockNavigation(false);
        setConsignmentsSequence({});
        setLastSequenceNumber(0);
      }
      if (isPlannedRoute) {
        handleRefreshPlannedRoutes();
      }
    }
    if (routeDiscardSuccess) {
      shouldBlockNavigationRef.current = false;
      setShouldBlockNavigation(false);
    }
    if (!updatedRoute && !routeDiscardSuccess) {
      if (editRoute) {
        onLoadRef.current = true;
        filterChangeRef.current = false;
        history.push(`${AppConstants.ROUTES.EDIT_ROUTE}/${editRoute.id}`, {
          plannedDate: editRoute.plannedDate,
          hubCode: editRoute.hubCode,
          hubName: editRoute.hubName,
          slots: editRoute.deliverySlot || [],
          route: editRoute,
          isExpress: isExpressFlow,
          redirect: {
            route: `${AppConstants.ROUTES.VIEW_ROUTE}/${editRoute.id}`,
            state: {
              plannedDate: editRoute.plannedDate,
              hubCode: editRoute.hubCode,
              hubName: editRoute.hubName,
              slots: editRoute.deliverySlot || [],
              route: editRoute,
              parentRoute: editRoute?.routeType,
            },
          },
        });
      }
    }
  }, [updatedRoute, routeDiscardSuccess, isExpressFlow]);

  useEffect(() => {
    if (routeConsignments) {
      const transformedConsignments: any = transformRouteConsignments(routeConsignments);
      const seqParams: any = addSequence(transformedConsignments.seqConsignments, "PLANNED", { allSequencedCons: {}, lastSeqNo: 0 });
      setConsignmentsSequence(seqParams.allSequencedCons);
      setSelectedConsignments(transformedConsignments.routeConsignments);
    }
  }, [routeConsignments]);

  useEffect(() => {
    if (shouldBlockNavigation) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (routeCreateSuccess) {
        initializeRoute({ date: currentDate, slotValue: slotValue, statusValue: statusValue, zoneValue: zoneValue });
      }
      if ((updatedRoute && !isPlannedRoute) || (routeDiscardSuccess && !noRedirect)) {
        let route: any = AppConstants.ROUTES.PLANNING;
        let state: any = {
          tabValue: AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.TAB_VALUES.ROUTES,
        };
        if (location.state && location.state.redirect) {
          route = location.state.redirect.route;
          state = updatedRoute ? { ...location.state.redirect.state, route: updatedRoute.route } : location.state.redirect.state;
          onLoadRef.current = true;
          filterChangeRef.current = false;
          setEditRoute(null);
        }
        handleClearCurrentSlot();
        history.push(route, state);
      }
      if (errorCode && errorCode === AppConstants.PLANNING_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.CONCURRENT_CONSIGNMENT) {
        initializeRoute({
          date: currentDate,
          slotValue: slotValue,
          statusValue: statusValue,
          zoneValue: zoneValue,
        });
      }
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldBlockNavigation, routeDiscardSuccess, updatedRoute, noRedirect]);

  useEffect(() => {
    if (consignmentDataAfterAddressUpdate) {
      const deliveryInformationAfterAddressUpdate = consignmentDataAfterAddressUpdate.deliveryInformation;
      const custInfoAfterAddressUpdate = deliveryInformationAfterAddressUpdate && deliveryInformationAfterAddressUpdate.customerContactInfo;
      const addressStatusAfterUpdate = custInfoAfterAddressUpdate?.address?.status;
      if (addressStatusAfterUpdate === ADDRESS_STATUS.UNVERIFIED) {
        setAddressAfterUnverrified({ ...addressAfterUnverrified, [selectedCode]: ADDRESS_STATUS.UNVERIFIED });
      }
    }
  }, [consignmentDataAfterAddressUpdate]);

  useEffect(() => {
    if (isAddressUpdated) {
      openSnackbarPopup(AppConstants.PLANNING_CONSTANTS.CONSIGNMENTS_DATA.ADDRESS_MARKED_UNVERIFIED, AppConstants.SNACKBAR.TYPES.SUCCESS);
    }
  }, [isAddressUpdated]);

  useEffect(() => {
    if (onLoadRef.current) {
      initialSteps();
    }
  }, [viewMode, editMode]);

  useEffect(() => {
    initialSteps();
    return () => {
      dispatch(resetRouteConsignmentsState());
      dispatch(resetSubrouteState());
      dispatch(updateAllConsignmentsState({ consignments: { areaWiseConsignments: [], slotwiseConsignments: {}, allConsignments: [], loading: false } }));
      sessionStorage.setItem("extraConsignments", "[]");
      if (editMode && sessionStorage.getItem("routeId")) {
        shouldBlockNavigationRef.current && handleDiscardRoute();
      } else {
        if (!viewMode) {
          handleOrderStatusOnUnload("UNPLANNED", true);
        }
      }
    };
  }, []);

  return (
    <div className={classes.root}>
      {loading && <Loader></Loader>}
      <Prompt when={shouldBlockNavigation} message={() => CreateRouteConstant.ON_EXIT_SCREEN_MESSAGE} />
      <Grid className={classes.container} container>
        <Grid className="createRtItem heading" container>
          <Grid item>
            <Grid container className="headingContainer">
              <Typography className="headingTitle">
                {editMode ? `Edit ${draftRoute?.route ? draftRoute?.route?.routeId || "Route" : ""}` : viewMode ? location?.state?.route?.routeId : "Create Route"}
              </Typography>
              {viewMode && (
                <Grid item>
                  <Grid className={classes.statusHeaderContainer} container>
                    <Typography className={["tag", getRouteStatusField("color", location.state.route.status)].join(" ")}>{location.state ? location.state.route.status : ""}</Typography>
                    {location.state.parentRoute === ROUTE_TYPE.PARENT_ROUTE && <Typography className={"tag parent"}>{ALL_ROUTES_TEXT_CONTANT.PARENT_ROUTE}</Typography>}
                    {!(isLastMileViewer || isOnlyStdLastMileViewer) && location.state.route.displayRouteStatus !== AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.UNEDITABLE_CHIP && (
                      <SvgIcon component={editIcon} className="editIcon" onClick={handleEditRoute} viewBox="0 0 24 24" />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {viewMode && location.state.route.displayRouteStatus === AppConstants.PLANNING_CONSTANTS.ROUTES_DATA.UNEDITABLE_CHIP && (
            <Grid item>
              <Grid container alignItems="center">
                <Button className="endSessionBtn secondary" variant="contained" onClick={() => handleOpenReleaseDriverHelperDialog()}>
                  {AppConstants.BUTTONS.END_DRIVER_HELPER_SESSION}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className="createRtItem content" item>
          <Grid className={classes.createRtContentContainer} container>
            <Grid className="contentItem left" item>
              <Grid className="consignmentsContainer" container>
                <Grid className="consignmentItem header" item>
                  <Grid container className={`headerSectionContainer ${viewMode ? "viewMode" : ""}`}>
                    <Grid className="headerSectionItem" item>
                      <Grid className="headerContainer" container>
                        <Grid className="headerItem heading" item>
                          <Grid container alignItems="center">
                            <Typography>
                              {viewMode ? "Consignments" : tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ? `Consignments(${allConsignments.length})` : "Routes"}
                            </Typography>
                            {!viewMode && tabValue !== AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ? (
                              <SvgIcon className="refreshIcon" component={refreshIcon} onClick={handleRefreshPlannedRoutes} viewBox="0 0 20 17" />
                            ) : null}
                          </Grid>
                        </Grid>
                        {!viewMode && (
                          <Grid className="headerItem icons" item>
                            {tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED && (
                              <TextBox
                                className="searchTextBox"
                                variant="outlined"
                                onChange={handleSearch}
                                value={consignmentSearchValue}
                                textBoxId="consignmentSearchTextbox"
                                name="consignmentSearch"
                                type="text"
                                placeholderText="Search Consignments/Area/Address"
                                endadornment={
                                  <InputAdornment position="end">
                                    <IconButton edge="end" size="small">
                                      <SvgIcon component={searchIcon} viewBox="0 0 16 16" />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              ></TextBox>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {!viewMode && (
                      <Grid className="headerSectionItem" item>
                        <Grid container className="filtersContainer">
                          <Grid className="filterItem" item>
                            <Typography className="filterName">Status</Typography>
                            <SelectBox
                              multiple
                              handleChange={handleStatusChange}
                              menuPropsList={classes.selectMenuPropsList}
                              menuPaperProps={classes.selectMenuPaperProps}
                              value={statusValue}
                              id="create-route-status-select-box"
                              items={consignmentStatusList || []}
                            ></SelectBox>
                          </Grid>
                          <Grid className="filterItem slot" item>
                            <Typography className="filterName">Slot</Typography>
                            <SelectBox
                              multiple
                              handleChange={handleSlotChange}
                              menuPropsList={classes.selectMenuPropsList}
                              menuPaperProps={classes.selectMenuPaperProps}
                              value={slotValue}
                              id="create-route-slot-select-box"
                              items={slotsList || []}
                            ></SelectBox>
                          </Grid>
                          <Grid className="filterItem zoneFilter" item>
                            <Typography className="filterName">Zone</Typography>
                            <SelectBox
                              multiple
                              handleChange={handleZoneChange}
                              menuPropsList={classes.selectMenuPropsList}
                              menuPaperProps={classes.selectMenuPaperProps}
                              value={zoneValue}
                              id="create-route-slot-select-box"
                              items={zoneList || []}
                            ></SelectBox>
                          </Grid>
                          {!editMode && (
                            <Grid className="filterItem" item>
                              <Typography className="filterName">Date</Typography>
                              <DateRangePickerComponent
                                keyId="create-route-date-picker"
                                className="icon-datepicker"
                                value={currentDate}
                                isTextField={false}
                                iconSrc={calendarIcon}
                                autoUpdateInput={false}
                                autoApply={true}
                                maxSpan={{ day: "0" }}
                                showDropdowns={true}
                                linkedCalendars={true}
                                locale={{ format: AppConstants.DATE_FORMAT }}
                                updateDateRange={handleDateChange}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid className="consignmentItem content" item>
                  {viewMode ? (
                    <>
                      <Grid className="contentContainer slotContentContainer viewMode">{getViewModeConsignments()}</Grid>
                    </>
                  ) : (
                    /** When Edit Route is clicked */
                    <Grid className="contentContainer">
                      <TabContext value={tabValue}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Consignments for Route Creation">
                          <Tab label="Unplanned" value={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED} />
                          <Tab label="Planned" value={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED} />
                        </Tabs>
                        <TabPanel className="tabPanel" value={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED}>
                          <Grid className="tabPanelContainer unplanned" container>
                            {areawiseConsignments?.length > 0 ? getAreawiseConsignments() : <Grid className="contentContainer slotContentContainer viewMode">{getViewModeConsignments()}</Grid>}
                          </Grid>
                        </TabPanel>
                        <TabPanel className="tabPanel" value={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED}>
                          <Grid className="tabPanelContainer planned" container>
                            {getPllanedAreawiseConsignments()}
                          </Grid>
                        </TabPanel>
                      </TabContext>
                      <Grid className={`hubLabel ${tabValue !== AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ? "isRoute" : ""}`}>
                        <Grid className="hubLabelContainer" container>
                          <Typography className="hubLabelText">
                            <span className="label">Hub:</span>
                            {location.state.hubName || ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      {tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED && areawiseConsignments?.length > 0 && (
                        <Grid className="toggleAll" onClick={toggleAllDropdowns}>
                          <Grid className="toggleAllContainer" container>
                            <Grid className="toggleItem" item>
                              <Typography className="toggleText">All</Typography>
                            </Grid>
                            <Grid className={`toggleItem iconItem ${toggleAllDropdownExpanded ? "expanded" : ""}`} item>
                              <SvgIcon className="toggleIcon" viewBox="0 0 21 21" component={downArrowIcon} />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid className="contentItem right" item>
              <Grid className="detailsContainer" container>
                {viewMode ? (
                  <Grid className="detailItem routeDetails viewMode" item>
                    <Grid className="routeDetailsContainer" container>
                      {location?.state?.route && (
                        <Grid className="routeDetailsItem header" item>
                          <Typography className="heading">{location.state.route && location.state.route.routeId}</Typography>
                          {/* {createRouteState?.refreshInterval > 0 && (
                              <Typography className="subHeading">{`Driver location refresh in ${createRouteState.minutes || ""} Min : ${createRouteState.seconds || ""} Sec`}</Typography>
                            )} */}
                        </Grid>
                      )}
                      {location?.state?.route ? (
                        <Grid className="routeDetailsItem basicDesc" item>
                          <Grid className="basicDescContainer" container>
                            <Grid className="basicItem descriptions" item>
                              <Grid className="descCommonContainer" container>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Driver</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{location.state.route && location.state.route.assignee ? location.state.route.assignee.name : "Not Assigned"}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Helper</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{location.state.route && location.state.route.helper ? location.state.route.helper.name : "Not Assigned"}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Vehicle</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{location.state.route && location.state.route.assignedVehicle ? location.state.route.assignedVehicle.name : "Not Assigned"}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Hub</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{location.state.route && location.state.route.hubName}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Delivery Area</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <CustomTooltip title={getSelectedAreasTooltip()}>
                                        <Typography className="columnValue">{getSelectedAreas()}</Typography>
                                      </CustomTooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid className="basicItem descriptions" item>
                              <Grid className="descCommonContainer" container>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Consignments</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{location.state.route && `${location.state.route.totalConsignment}`.padStart(2, "0")}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Crates</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{location.state.route && `${location.state.route.totalCrates}`.padStart(2, "0")}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Delivery Slot</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <CustomTooltip title={getSelectedSlotsTooltip()}>
                                        <Typography className="columnValue">{getSelectedDeliverySlots()}</Typography>
                                      </CustomTooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Delivery Date</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{getCurrentSelectedDate()}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Transit Time</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{getTransitTime()}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>{`${ALL_ROUTES_TEXT_CONTANT.ACTUAL_DISTANCE}`}</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{location?.state?.route?.actualTransitDistance?.formattedDistance || ""}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid className="routeDetailsItem noSelectedArea" item>
                          <Typography>Route Details will appear here</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ) : tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ? (
                  <Grid className="detailItem routeDetails" item>
                    <Grid className="routeDetailsContainer" container>
                      <Grid className="routeDetailsItem header" item>
                        <Grid container className="routeIdHeaderContainer">
                          <Grid item className="headingItem">
                            <Typography className="heading">{draftRoute.route && draftRoute.route.routeId}</Typography>
                          </Grid>
                          {getAllSelectedConsignments().length || draftRoute?.route ? (
                            <Grid item className="ddnsItem">
                              <SelectBox
                                handleChange={handleDriverChange}
                                value={driverValue}
                                id="driver-select-box"
                                label="Driver"
                                enableSearch={true}
                                customWidthSearchBar={true}
                                items={getUpdatedDdnList("driver", draftRoute.route)}
                                showSelectNone={checkUnselectVisibility()}
                              ></SelectBox>
                              <SelectBox
                                handleChange={handleHelperChange}
                                value={helperValue}
                                enableSearch={true}
                                customWidthSearchBar={true}
                                id="helper-select-box"
                                label="Helper"
                                items={getUpdatedDdnList("helper", draftRoute.route)}
                                showSelectNone={false}
                              ></SelectBox>
                              <SelectBox
                                handleChange={handleVehicleChange}
                                value={vehicleValue}
                                id="vehicle-select-box"
                                label="Vehicle"
                                items={getUpdatedDdnList("vehicle", draftRoute.route)}
                                showSelectNone={checkUnselectVisibility()}
                              ></SelectBox>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                      {getAllSelectedConsignments().length || draftRoute?.route?.id ? (
                        <Grid className="routeDetailsItem basicDesc" item>
                          <Grid className="basicDescContainer" container>
                            <Grid className="basicItem descriptions" item>
                              <Grid className="descCommonContainer" container>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Delivery Area</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      {getAllSelectedConsignments().length ? (
                                        <CustomTooltip title={getSelectedAreasTooltip()}>
                                          <Typography className="columnValue">{getSelectedAreas()}</Typography>
                                        </CustomTooltip>
                                      ) : (
                                        <CustomTooltip title={getDraftSelectedAreasTooltip()}>
                                          <Typography className="columnValue">{getDraftSelectedAreas()}</Typography>
                                        </CustomTooltip>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Consignments</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Grid container className="capacityBreachContainer">
                                        {getAllSelectedConsignments().length ? (
                                          <Typography>{`${getAllSelectedConsignments().length}`.padStart(2, "0")}</Typography>
                                        ) : (
                                          <Typography>{`${draftRoute?.route?.consignmentsIds?.length}`.padStart(2, "0")}</Typography>
                                        )}
                                        {calculateCapacityBreach("consignments") && (
                                          <CustomTooltip placement="bottom-start" title={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CONSIGNMENTS}>
                                            <SvgIcon className="infoErrorIcon" viewBox="0 0 16 16" component={infoErrorIcon} />
                                          </CustomTooltip>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Crates</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Grid container className="capacityBreachContainer">
                                        {getAllSelectedConsignments().length ? (
                                          <Typography>{`${getTotalCrates(getAllSelectedConsignments())}`.padStart(2, "0")}</Typography>
                                        ) : (
                                          <Typography>{`${draftRoute?.route?.totalCrates}`.padStart(2, "0")}</Typography>
                                        )}
                                        {calculateCapacityBreach("crates") && (
                                          <CustomTooltip placement="bottom-start" title={AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.CAPACITY_BREACH_MSGS.CRATES}>
                                            <SvgIcon className="infoErrorIcon" viewBox="0 0 16 16" component={infoErrorIcon} />
                                          </CustomTooltip>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Delivery Slot</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      {getAllSelectedConsignments().length ? (
                                        <CustomTooltip title={getSelectedSlotsTooltip()}>
                                          <Typography className="columnValue">{getSelectedDeliverySlots()}</Typography>
                                        </CustomTooltip>
                                      ) : (
                                        <CustomTooltip title={getDraftSelectedSlotsTooltip()}>
                                          <Typography className="columnValue">{getDraftSelectedDeliverySlots()}</Typography>
                                        </CustomTooltip>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Delivery Date</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{getCurrentSelectedDate()}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Transit Time</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{getTransitTime()}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>{`${ALL_ROUTES_TEXT_CONTANT.ACTUAL_DISTANCE}`}</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{`${draftRoute?.route?.actualTransitDistance?.formattedDistance || ""}`}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid className="routeDetailsItem noSelectedArea" item>
                          <Typography>Selected Consignments will appear here</Typography>
                        </Grid>
                      )}
                      {getAllSelectedConsignments().length > 0 && draftRoute?.route?.routeType !== ROUTE_TYPE.PARENT_ROUTE && draftRoute?.route?.routeType !== ROUTE_TYPE.SUB_ROUTE &&  !isRouteStatusOutForDelivery(location?.state?.route?.status) && (
                        <>
                          <Grid className="routeDetailsItem timelineWarning" item>
                            <Grid container className="warningContainer">
                              <Grid item className="warningItem">
                                <SvgIcon className="warningIcon" component={infoWarningIcon} viewBox="0 0 20 20" />
                              </Grid>
                              <Grid item className="warningItem">
                                <Typography>You can also drag and reorder the consigments to change their sequence.</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid className="routeDetailsItem timeline" item>
                            <Typography component="div" className="content">
                              <DragDropContext onDragStart={onConsignmentsDragStart} onDragEnd={onConsignmentsDragEnd}>
                                <Droppable droppableId="droppableArea" direction="horizontal" ignoreContainerClipping>
                                  {(provided) => (
                                    <Stepper
                                      className={classes.timelineStepper}
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      alternativeLabel
                                      connector={<StepperTimelineConnector hidden={draggingItem} />}
                                    >
                                      <Step className="timelineStep first">
                                        <StepLabel className="stepLabel" StepIconComponent={areaTimelineIcon}>
                                          {draftRoute.route && draftRoute.route.hubName}
                                        </StepLabel>
                                      </Step>
                                      {getStepperTimelineItems()}
                                      {provided.placeholder}
                                    </Stepper>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid className="detailItem routeDetails planned" item>
                    <Grid className="routeDetailsContainer" container>
                      {selectedRoute?.code && selectedRoute?.value && (
                        <Grid className="routeDetailsItem header" item>
                          <Typography className="heading">{selectedRoute && selectedRoute.value}</Typography>
                        </Grid>
                      )}
                      {selectedRoute.code ? (
                        <Grid className="routeDetailsItem basicDesc" item>
                          <Grid className="basicDescContainer" container>
                            <Grid className="basicItem descriptions" item>
                              <Grid className="descCommonContainer" container>
                                <Grid className="commonItem" item>
                                  <SelectBox
                                    handleChange={handleRouteDriverChange}
                                    value={routeDriverValue}
                                    id="route-driver-select-box"
                                    label="Driver"
                                    items={getUpdatedDdnList("driver", selectedRoute.data)}
                                    showSelectNone={true}
                                  ></SelectBox>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <SelectBox
                                    handleChange={handleRouteHelperChange}
                                    value={routeHelperValue}
                                    id="route-helper-select-box"
                                    label="Helper"
                                    items={getUpdatedDdnList("helper", selectedRoute.data)}
                                    showSelectNone={true}
                                  ></SelectBox>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <SelectBox
                                    handleChange={handleRouteVehicleChange}
                                    value={routeVehicleValue}
                                    id="route-vehicle-select-box"
                                    label="Vehicle"
                                    items={getUpdatedDdnList("vehicle", selectedRoute.data)}
                                    showSelectNone={true}
                                  ></SelectBox>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Hub</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{selectedRoute.data && selectedRoute.data.hubName}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Delivery Area</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <CustomTooltip title={getSelectedAreasTooltip()}>
                                        <Typography className="columnValue">{getSelectedAreas()}</Typography>
                                      </CustomTooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid className="basicItem descriptions" item>
                              <Grid className="descCommonContainer" container>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Consignments</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{selectedRoute.data && `${selectedRoute.data.totalConsignment}`.padStart(2, "0")}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Crates</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{selectedRoute.data && `${selectedRoute.data.totalCrates}`.padStart(2, "0")}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Delivery Slot</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <CustomTooltip title={getSelectedSlotsTooltip()}>
                                        <Typography className="columnValue">{getSelectedDeliverySlots()}</Typography>
                                      </CustomTooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Delivery Date</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{getCurrentSelectedDate()}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>Transit Time</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{getTransitTime()}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="commonItem" item>
                                  <Grid className="descContainer" container>
                                    <Grid className="descItem title" item>
                                      <Typography>{`${ALL_ROUTES_TEXT_CONTANT.ACTUAL_DISTANCE}`}</Typography>
                                    </Grid>
                                    <Grid className="descItem value" item>
                                      <Typography>{selectedRoute?.data?.actualTransitDistance?.formattedDistance || ""}</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid className="routeDetailsItem noSelectedArea" item>
                          <Typography>Selected Route Details will appear here</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid className="detailItem mapZone" item>
                  {tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED && !selectedRoute.code ? null : isListView ? (
                    <Typography component="div" className="listViewTimelineContent">
                      <Timeline className={classes.listViewTimeline}>
                        <TimelineItem className="timelineItem first">
                          <TimelineSeparator>
                            <TimelineDot className="timelineDot">
                              <SvgIcon className="timelineIcon areaIcon" component={areaTimelineIcon} viewBox="0 0 32 32" />
                            </TimelineDot>
                            <TimelineConnector className="primaryConnector" />
                          </TimelineSeparator>
                          <TimelineContent className="timelineContent first">
                            {tabValue !== AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED && (
                              <Typography className="titleHeading">{viewMode ? location.state.route && location.state.route.hubName : draftRoute.route && draftRoute.route.hubName}</Typography>
                            )}
                            {tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.PLANNED && <Typography className="titleHeading">{selectedRoute?.value}</Typography>}
                          </TimelineContent>
                        </TimelineItem>
                        {isNonEmptyObject(getDockAddressObject()) && showDockAddressTimeLine()}
                        {editMode && subRoutes?.length > 0 ? (
                          <>
                            {routeConsignments?.length > 0 && getCustomSubRouteTimelineItems()}
                            {getListViewSubRouteTimelineItems()}
                          </>
                        ) : getAllSelectedConsignments().length ? (
                          <>
                            {getListViewTimelineItems()}
                            {getListViewSubRouteTimelineItems()}
                          </>
                        ) : subRoutes?.length > 0 ? (
                          getListViewSubRouteTimelineItems()
                        ) : (
                          routeConsignments?.length > 0 && getCustomSubRouteTimelineItems()
                        )}
                      </Timeline>
                    </Typography>
                  ) : (
                    <MapContainer
                      center={mapCenterState}
                      zoom={12}
                      markers={mapMarkers}
                      showDirections={true}
                      roundTrip={true}
                      optimizeRoute={optimizeMapRoute}
                      updateMap={updateMap}
                      mapOptionStyles={mapOptionStyles}
                    ></MapContainer>
                  )}
                  <Grid className="mapSwitchBtnContainer" container>
                    <Grid className={`switchBtnItem mapView ${isListView ? "" : "selected"}`} onClick={() => toggleListView("map")} item>
                      <Grid container className="switchBtnItemContainer">
                        <SvgIcon className="switchBtnIcon" component={mapViewIcon} htmlColor="transparent" viewBox="0 0 21 20" />
                        <Typography className="title">Map</Typography>
                      </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid className={`switchBtnItem listView ${isListView ? "selected" : ""}`} onClick={() => toggleListView("list")} item>
                      <Grid container className="switchBtnItemContainer">
                        <SvgIcon className="switchBtnIcon" component={listViewIcon} viewBox="0 0 20 20" />
                        <Typography className="title">List</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {!viewMode ? (
                  <Grid className="detailItem buttons" item>
                    <Grid className="buttonsContainer" container>
                      {!isListView && (
                        <>
                          <Button className="refreshMapBtn secondary btn" variant="contained" onClick={handleRefreshMap}>
                            {AppConstants.BUTTONS.REFRESH_MAP}
                          </Button>
                          <Button className="optimizeBtn secondary btn" variant="contained" disabled={disableOptimizeMapRoute()} onClick={handleOptimizeMapRoute}>
                            {AppConstants.BUTTONS.OPTIMIZE}
                          </Button>
                        </>
                      )}
                      {tabValue === AppConstants.PLANNING_CONSTANTS.CREATE_ROUTE_DATA.TAB_VALUES.UNPLANNED ? (
                        editMode ? (
                          <>
                            <Button className="discardBtn secondary btn" variant="contained" onClick={handleDiscardChanges}>
                              {AppConstants.BUTTONS.DISCARD}
                            </Button>
                            <Button className="saveBtn primary" disabled={disableCreateRt() || disableEditRt()} variant="contained" onClick={handleUpdateRoute}>
                              {AppConstants.BUTTONS.SAVE}
                            </Button>
                          </>
                        ) : (
                          <Button className="createRtBtn primary btn" disabled={disableCreateRt()} variant="contained" onClick={() => handleCreateRoute(true)}>
                            {AppConstants.BUTTONS.CREATE_ROUTE}
                          </Button>
                        )
                      ) : (
                        <Button className="saveBtn primary" disabled={!selectedRoute.data} variant="contained" onClick={handleUpdatePlannedRoute}>
                          {AppConstants.BUTTONS.SAVE}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openConsignmentDetailsPopup && (
        <ConsignmentDetails
          isFromViewRoute={true}
          isEditIcon={isEditIcon}
          open={openConsignmentDetailsPopup}
          consignment={consignmentData}
          closePopup={closeConsignmentDetailsPopup}
        ></ConsignmentDetails>
      )}
      {openDiscardConfirmDialog && (
        <CustomDialog
          open={openDiscardConfirmDialog}
          PaperProps={confirmDialogPaperProps}
          title={getChangeDialogTitleContent()}
          content={getDiscardDialogDetailsContent()}
          actions={getDiscardDialogActions()}
          handleClose={handleDiscardDialogClose}
        ></CustomDialog>
      )}
      {openDateChangeConfirmDialog && (
        <CustomDialog
          open={openDateChangeConfirmDialog}
          PaperProps={confirmDialogPaperProps}
          title={getChangeDialogTitleContent()}
          content={getChangeDialogDetailsContent()}
          actions={getChangeDialogActions()}
          handleClose={handleChangeDialogClose}
        ></CustomDialog>
      )}
      {openUpdateConfirmDialog && (
        <CustomDialog
          open={openUpdateConfirmDialog}
          PaperProps={confirmDialogPaperProps}
          title={getUpdateDialogTitleContent()}
          content={getUpdateDialogDetailsContent()}
          actions={getUpdateDialogActions()}
          handleClose={handleUpdateDialogClose}
        ></CustomDialog>
      )}
      {openCapacityBreachConfirmDialog && (
        <CustomDialog
          open={openCapacityBreachConfirmDialog}
          PaperProps={confirmWithTitleDialogProps}
          title={getCapacityBreachDialogTitleContent()}
          content={getCapacityBreachDialogDetailsContent()}
          actions={getCapacityBreachDialogActions()}
          handleClose={handleCapacityBreachDialogClose}
        ></CustomDialog>
      )}
      {openReleaseDialog && (
        <CustomDialog
          open={openReleaseDialog}
          PaperProps={endSessionSelectionDialogPaperPropsRoot}
          title={getReleaseDialogTitleContent()}
          content={getReleaseDialogDetailsContent()}
          actions={getReleaseDialogActions()}
          handleClose={handleReleaseDialogClose}
        ></CustomDialog>
      )}
      {openAddressPopup && (
        <CustomDialog
          hideCloseIcon={true}
          open={openAddressPopup}
          title={getAddressDialogTitleContent()}
          content={getAddressDialogDetailsContent()}
          actions={getAddressDialogActions()}
          handleClose={handleAddressDialogClose}
        ></CustomDialog>
      )}
      {openSnackbar && (
        <CustomSnackbar
          open={openSnackbar}
          handleClose={handleSnackbarClose}
          onExited={handleSnackbarExited}
          autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT}
          message={snackbarMessage.current}
          type={snackbarType.current}
        />
      )}
      {openReleaseSnackbar && isReleaseAssignee && (
        <CustomSnackbar open={openReleaseSnackbar} handleClose={handleReleaseSnackbarClose} autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT} message={releaseMessage} />
      )}
    </div>
  );
};

export default CreateRoute;
