import moment from "moment";
import AppConstants from "../../constants";
import { EXPRESS_DELIVERY_TYPE } from '../../constants/ExpressConstants';
import { getUniqueValues, fixDecimals, transformIntoObj, transformIntoObjArrays, calculateTimeBetweenSlots, isUndefined, transformForScalable, displayUnitGrams, getValueFromArray } from "../../utils/helpers.utils";

export interface MetaData {
  paymentType: string;
  totalAmount: string;
  transactionDate: Date;
  amountToCollect: string;
  collectedAmount: string;
  currency: string;
  category: string;
  deliveryType: string;
  modeOfCollection: string;
  invoiceNumber: string;
  internalExternal: string;
  origin: string;
  status: string;
  deliveryStatus: string;
  routeId: string;
  routeName: string;
  routeStatus: string;
  numberOfItems: number;
  lastStatusUpdatedTime: string;
  deliveryZone: string;
  activity: string;
}
export interface Name {
  ar: string;
  en: string;
}
export interface Product {
  sku: string;
  entryNumber: string;
  hasRejectedProducts: boolean;
  name: Name;
  quantity: string;
  acceptedQty: string
  unitPrice: string;
  imgUrl: string;
  vatPercentage: string;
  uom: string;
  orderedBarcode: string;
  associatedBarcodes: string[];
  substitution: boolean;
}
export interface Address {
  area: string;
  landmark: string;
  building: string;
  apartment: string;
  zoneNumber: string;
  makani: string;
  streetNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
  countryName: string;
  countryIsoCode: string;
  longitude: string;
  latitude: string;
  status: string;
}
export interface ContactInfo {
  name: string;
  phone: string;
  altphone: string;
  email: string;
  address: Address;
}

export interface PosInformation {
  store: string;
  storeName: string;
  contactInfo: ContactInfo;
}

export interface DeliverySlot {
  startTime: Date;
  endTime: Date;
  promiseDeliveryTime: any;
  deliverySlotTime: string;
}

export interface CustomerContactInfo {
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface DeliveryInformation {
  deliveryInstructions: string;
  deliveryCost: string;
  carrier: string;
  carrierTrackingId: string;
  shipmentSize: string;
  totalCratesCount: number;
  deliverySlot: DeliverySlot;
  customerContactInfo: CustomerContactInfo;
  driver: Driver;
  helper: Driver;
  preferredVehicle: string;
  transitTime: string;
  actualTransitTimeInMin: string;
  dynamicTransitTime: string;
  estimatedTransitTime: string;
  deliveryExecutionStatus: string;
}

export interface PickUpContactInfo {
  companyName: string;
  name: string;
  phone: string;
  email: string;
  address: Address;
}

export interface PickupSlot {
  startTime: Date;
  endTime: Date;
}

export interface PickUpInformation {
  pickUpContactInfo: PickUpContactInfo;
  pickupSlot: PickupSlot;
}

export interface Reason {
  deliveryStatus: string;
  reasonCode: string;
  reasonDescription: string;
}

export interface Reason {
  deliveryStatus: string;
  reasonCode: string;
  reasonDescription: string;
}
export interface Order {
  id: string;
  orderCode: string,
  consignmentCode: string,
  metadata: MetaData;
  posInformation: PosInformation;
  deliveryInformation: DeliveryInformation;
  reason: Reason;
  products: Product[];
  createdAt: string;
}

export interface CountryDetails {
  countryCode: string;
  countryName: string;
}

export interface AssignedStatus {
  vehicleAssigned: boolean;
  helperAssigned: boolean;
  driverAssigned: boolean;
  consignmentsAvailable: boolean;
}

export interface Vehicle {
  brand: string;
  country: string;
  crateCapacity: number;
  fleetId: string;
  id: string;
  orderCapacity: number;
  type: string;
  name: string;
}

export interface Driver {
  countryIsoCode: string;
  countryName: string;
  licenseNumber: number;
  driverId: string;
  driverName: string;
  type: string;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  requestContinuation: string;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort2 {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface ExpressOrdersDataResponse {
  pageNumber: number;
  totalElements: number;
  totalPages: number;
  continuationToken?: string;
  elements: Order[];
}

export interface Statuses {
  statusCode: string;
  statusName: string;
  statusDate: string;
  reason: Reason;
}

export interface SubsProducts {
  sku: string;
  name: Name;
  quantity: string;
  shippedQty: string;
  rejectedQty: string
  unitPrice: string;
  vatPercentage: string;
  uom: string;
  reason: Reason;
}
export interface ProductsDataTransform {
  sku: string;
  name: Name;
  entryNumber: string;
  quantity: string;
  shippedQty: any;
  rejectedQty: string;
  unitPrice: string;
  vatPercentage: string;
  uom: string;
  reason: Reason;
  substitution: boolean;
  substitutedProducts: SubsProducts[];
}

export interface StandardProducts {
  products: ProductsDataTransform[]
}

export const expressOrdersDataTransform = (response: ExpressOrdersDataResponse): any[] => {
  const transformedArray: any[] = [];
  response.elements.forEach((order: Order) => {
    const {
      id,
      metadata,
      posInformation,
      deliveryInformation,
      orderCode,
      createdAt,
      products,
      reason,
      consignmentCode,
    } = order;
    const deliveryDate = moment(deliveryInformation && deliveryInformation.deliverySlot && deliveryInformation.deliverySlot.startTime, AppConstants.DATE_FORMAT_BACKEND);
    const customerContactInfo = (deliveryInformation && deliveryInformation.customerContactInfo) || {
      address: {}
    };
    const transactionDate = moment(createdAt, AppConstants.DATE_FORMAT_BACKEND);
    //const timeBetweenSlots = calculateTimeBetweenSlots(startTime, endTime);
    transformedArray.push({
      id: id,
      hubCode: posInformation && posInformation.store,
      hubNames: posInformation && posInformation.storeName,
      consignmentId: consignmentCode,
      status: metadata && metadata.status,
      deliveryStatus: metadata && metadata.deliveryStatus,
      deliveryArea: customerContactInfo && customerContactInfo.address ? customerContactInfo.address.area : "",
      deliveryDate: deliveryDate.format(AppConstants.DATE_FORMAT),
      numberOfItems: products && products.length ? products.length : 0,
      deliverySlotTime: deliveryInformation?.deliverySlot?.deliverySlotTime,
      promiseTime: deliveryInformation && deliveryInformation.deliverySlot && deliveryInformation.deliverySlot.promiseDeliveryTime ? deliveryInformation.deliverySlot.promiseDeliveryTime + " mins" : "-",
      orderId: orderCode,
      transactionDate: transactionDate.format(AppConstants.DATE_FORMAT),
      customerName: customerContactInfo.name,
      deliveryStreetAddress: getAddressInfo(customerContactInfo.address),
      deliveryZone: metadata && metadata?.deliveryZone ? metadata?.deliveryZone : "",
      driverName: deliveryInformation && deliveryInformation.driver ? deliveryInformation.driver.driverName : "",
      vehicle: deliveryInformation && deliveryInformation.preferredVehicle ? deliveryInformation.preferredVehicle : "",
      paymentType: metadata && metadata.paymentType,
      totalAmount: fixDecimals(2, metadata && metadata.totalAmount ? metadata.totalAmount.toString() : ""),
      codAmount: fixDecimals(2, metadata && metadata.amountToCollect ? metadata.amountToCollect.toString() : ""),
      collectedAmount: metadata && metadata.collectedAmount ? parseFloat(metadata.collectedAmount.toString()).toFixed(2) : "0.00",
      deliveryActualTime: deliveryInformation.actualTransitTimeInMin,
      deliveryExecutionStatus: deliveryInformation ? deliveryInformation.deliveryExecutionStatus : "-",
      address: customerContactInfo && customerContactInfo.address ? getAddressInfo(customerContactInfo.address) : "-",
      actions: getActionItem(deliveryInformation, metadata?.deliveryStatus),
      activity: metadata && metadata?.activity,
      addressStatus: customerContactInfo && customerContactInfo.address ? customerContactInfo.address.status : "",
      undeliveredReason: reason ? reason.reasonDescription : "",
      customerEmail: customerContactInfo?.email || ""
    });
  });
  return [...transformedArray];
};

export const getActionItem = (deliveryInformation: any, status: any) => {
  switch (status) {
    case EXPRESS_DELIVERY_TYPE.UNASSIGNED:
      return "Assign";
    case EXPRESS_DELIVERY_TYPE.ASSIGNED:
      return "Reassign";
    case EXPRESS_DELIVERY_TYPE.DELIVERY_ATTEMPTED:
      return "Reassign";
    case EXPRESS_DELIVERY_TYPE.ON_HOLD:
      return deliveryInformation?.driver?.driverId ? "Reassign" : "Assign";
    default:
      return "";
  }
}

export const getConsignmentStatusField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.STATUSES;
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
}

export const driverStatusField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DRIVER_STATUSES;
  let newStatus = statusCode.toUpperCase();
  return statusCode && statuses[newStatus] ? statuses[newStatus][fieldName] : "";
}

export const getOrderStatusField = (fieldName: string, statusCode: string) => {
  const statuses: any = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.ORDER_STATUSES;
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
}

export const getDeliveryExecutionStatusField = (fieldName: string, statusCode:string) =>{
  let statuses: any = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DELIVERY_EXECUTION_STATUS;
  if(statusCode === "ON-TIME"){
    statuses[statusCode] = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DELIVERY_EXECUTION_STATUS.ON_TIME;
  }
  return statusCode && statuses[statusCode] ? statuses[statusCode][fieldName] : "";
}

const getFilterAttributeValue = (attrValue: any, noKey?: boolean) => {
  let currentValue = noKey ? attrValue : attrValue.map((item: any) => item.value);
  const selectAllIdx = currentValue.indexOf(AppConstants.SELECT_ALL.value);
  const selectNoneIdx = currentValue.indexOf(AppConstants.SELECT_NONE.value);
  if (selectAllIdx > -1) {
    currentValue = [];
  }
  if (selectNoneIdx > -1) {
    currentValue = [];
  }
  return currentValue;
}

export const createChipsCountFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, countryCode?: any) => {
  payload.attributes = [];
  if (countryCode) {
    payload.countryISOCode = countryCode
  }
  if (payload.dateRange) {
    if (payload.dateRange && payload.dateRange.value) {
      payload.isDateRange = true;
      payload.dateRange = {
        startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
        endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
      };
    }
  }
  if (payload.hub.value.length > 0) {
    let hubValue = payload.hub.value.map((item: any) => item.value);
    let hubNameValue = [];
    if (payload.hubName && payload.hubName.value.length) {
      hubNameValue = payload.hubName.value.map((item: any) => item.value);
    }
    hubValue = hubValue.concat(hubNameValue);
    const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      hubValue = [];
    }
    if (selectNoneIdx > -1) {
      hubValue = [];
    }
    if (hubValue.length) {
      payload.attributes.push({
        key: payload.hub.key,
        value: getUniqueValues(hubValue)
      })
    }
    delete payload.hub;
  } else {
    delete payload.hub;
  }
  if (payload.hubName.value.length > 0) {
    let attrValue = [];
    if (payload.hubName.value) {
      attrValue = getFilterAttributeValue(payload.hubName.value);
      payload.attributes.push({
        key: "hubNames",
        value: attrValue
      })
    }
  } else {
    delete payload.hubName;
  }
  if (payload.deliveryArea.value.length > 0) {
    let attrValue = [];
    if (payload.deliveryArea.value) {
      attrValue = getFilterAttributeValue(payload.deliveryArea.value);
      payload.attributes.push({
        key: payload.deliveryArea.key,
        value: attrValue
      })
    }
  } else {
    delete payload.deliveryArea;
  }
  if (payload.deliveryStatus.value.length > 0) {
    let attrValue = [];
    if (payload.deliveryStatus.value) {
      attrValue = getFilterAttributeValue(payload.deliveryStatus.value);
      payload.attributes.push({
        key: payload.deliveryStatus.key,
        value: attrValue
      })
    }
  } else {
    delete payload.deliveryStatus;
  }
  if (payload.status.value.length > 0) {
    let attrValue = [];
    if (payload.status.value) {
      attrValue = getFilterAttributeValue(payload.status.value);
      payload.attributes.push({
        key: payload.status.key,
        value: attrValue
      })
    }
  } else {
    delete payload.status;
  }
  if (payload.deliveryExecutionStatus.value.length > 0) {
    let attrValue = [];
    if (payload.deliveryExecutionStatus.value) {
      attrValue = getFilterAttributeValue(payload.deliveryExecutionStatus.value);
      payload.attributes.push({
        key: payload.deliveryExecutionStatus.key,
        value: attrValue
      })
    }
  } else {
    delete payload.deliveryExecutionStatus;
  }
  if (payload.driverId.value.length > 0) {
    let attrValue = [];
    if (payload.driverId.value) {
      attrValue = getFilterAttributeValue(payload.driverId.value);
      payload.attributes.push({
        key: payload.driverId.key,
        value: attrValue
      })
    }
  } else {
    delete payload.driverId;
  }
  if (payload.deliverySlotTime.value.length > 0) {
    let attrValue = [];
    if (payload.deliverySlotTime.value) {
      attrValue = getFilterAttributeValue(payload.deliverySlotTime.value);
      payload.attributes.push({
        key: payload.deliverySlotTime.key,
        value: attrValue
      })
    }
  } else {
    delete payload.deliverySlotTime;
  }
  if (payload.paymentType.value.length > 0) {
    let attrValue = [];
    if (payload.paymentType.value) {
      attrValue = getFilterAttributeValue(payload.paymentType.value);
      payload.attributes.push({
        key: payload.paymentType.key,
        value: attrValue
      })
    }
  } else {
    delete payload.paymentType;
  }

  delete payload.deliveryArea;
  delete payload.deliveryStatus;
  delete payload.status;
  delete payload.deliveryExecutionStatus;
  delete payload.deliverySlotTime;
  delete payload.driverId;
  delete payload.hub;
  delete payload.hubName;
  delete payload.paymentType;
  delete payload.vehicle;
  delete payload.reset;
  if (isLoadMore && data) {
    payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
  }
  payload.isDateRange = true;
  return payload;
};

export const createFilterPayload = (payload: any, data?: any, isLoadMore?: boolean, countryCode?: any, selectedChip?: any) => {
  payload.attributes = [];
  if (countryCode) {
    payload.countryISOCode = countryCode
  }
  if (payload.dateRange) {
    if (payload.dateRange && payload.dateRange.value) {
      payload.isDateRange = true;
      payload.dateRange = {
        startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
        endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
      };
    }
  }
  if (payload.hub.value.length > 0) {
    let hubValue = payload.hub.value.map((item: any) => item.value);
    let hubNameValue = [];
    if (payload.hubName && payload.hubName.value.length) {
      hubNameValue = payload.hubName.value.map((item: any) => item.value);
    }
    hubValue = hubValue.concat(hubNameValue);
    const selectAllIdx = hubValue.indexOf(AppConstants.SELECT_ALL.value);
    const selectNoneIdx = hubValue.indexOf(AppConstants.SELECT_NONE.value);
    if (selectAllIdx > -1) {
      hubValue = [];
    }
    if (selectNoneIdx > -1) {
      hubValue = [];
    }
    if (hubValue.length) {
      payload.attributes.push({
        key: payload.hub.key,
        value: getUniqueValues(hubValue)
      })
    }
    delete payload.hub;
  } else {
    delete payload.hub;
  }
  if (payload.hubName.value.length > 0) {
    let attrValue = [];
    if (payload.hubName.value) {
      attrValue = getFilterAttributeValue(payload.hubName.value);
      payload.attributes.push({
        key: "hubNames",
        value: attrValue
      })
    }
  } else {
    delete payload.hubName;
  }
  if (payload.deliveryArea.value.length > 0) {
    let attrValue = [];
    if (payload.deliveryArea.value) {
      attrValue = getFilterAttributeValue(payload.deliveryArea.value);
      payload.attributes.push({
        key: payload.deliveryArea.key,
        value: attrValue
      })
    }
  } else {
    delete payload.deliveryArea;
  }
   if (payload.addressStatus.value.length > 0) {
     let attrValue = [];
     if (payload.addressStatus.value) {
       attrValue = getFilterAttributeValue(payload.addressStatus.value);
     } else {
       attrValue = [];
     }
     payload.attributes.push({
       key: payload.addressStatus.key,
       value: attrValue,
     });
   }
    else {
    delete payload.addressStatus;
  }
  if (payload.activity.value.length > 0) {
    let attrValue = [];
    if (payload.activity.value) {
      attrValue = getFilterAttributeValue(payload.activity.value);
    } else {
      attrValue = [];
    }
    payload.attributes.push({
      key: payload.activity.key,
      value: attrValue,
    });
  }
   else {
   delete payload.activity;
 }
  if (payload.deliveryZone.value.length > 0) {
    let attrValue = [];
    if (payload.deliveryZone.value) {
      attrValue = getFilterAttributeValue(payload.deliveryZone.value);
      payload.attributes.push({
        key: payload.deliveryZone.key,
        value: attrValue
      })
    }
  } else {
    delete payload.deliveryZone;
  }
  if (payload.deliveryStatus.value.length > 0) {
    let attrValue = [];
    if (payload.deliveryStatus.value) {
      attrValue = getFilterAttributeValue(payload.deliveryStatus.value);
      payload.attributes.push({
        key: payload.deliveryStatus.key,
        value: attrValue
      })
    }
  } else {
    delete payload.deliveryStatus;
  }
  if (payload.deliveryExecutionStatus.value.length > 0) {
    let attrValue = [];
    if (payload.deliveryExecutionStatus.value) {
      attrValue = getFilterAttributeValue(payload.deliveryExecutionStatus.value);
      payload.attributes.push({
        key: payload.deliveryExecutionStatus.key,
        value: attrValue
      })
    }
  } else {
    delete payload.deliveryExecutionStatus;
  }
  if (payload.status.value.length > 0) {
    let attrValue = [];
    if (payload.status.value) {
      attrValue = getFilterAttributeValue(payload.status.value);
      payload.attributes.push({
        key: payload.status.key,
        value: attrValue
      })
    }
  } else {
    delete payload.status;
  }
  if (payload.driverId.value.length > 0) {
    let attrValue = [];
    if (payload.driverId.value) {
      attrValue = getFilterAttributeValue(payload.driverId.value);
      payload.attributes.push({
        key: payload.driverId.key,
        value: attrValue
      })
    }
  } else {
    delete payload.driverId;
  }
  if (payload.deliverySlotTime.value.length > 0) {
    let attrValue = [];
    if (payload.deliverySlotTime.value) {
      attrValue = getFilterAttributeValue(payload.deliverySlotTime.value);
      payload.attributes.push({
        key: payload.deliverySlotTime.key,
        value: attrValue
      })
    }
  } else {
    delete payload.deliverySlotTime;
  }
  if (payload.paymentType.value.length > 0) {
    let attrValue = [];
    if (payload.paymentType.value) {
      attrValue = getFilterAttributeValue(payload.paymentType.value);
      payload.attributes.push({
        key: payload.paymentType.key,
        value: attrValue
      })
    }
  } else {
    delete payload.paymentType;
  }
  if (selectedChip !== "UNASSIGNED") {
    if (selectedChip === "ALL") {
      let obj = {
        key: "deliveryGroupStatus",
        value: []
      }
      payload.attributes.push(obj);
    } else {
      let obj = {
        key: "deliveryGroupStatus",
        value: [selectedChip]
      }
      payload.attributes.push(obj);
    }
  } else {
    let obj = {
      key: "deliveryGroupStatus",
      value: ["UNASSIGNED"]
    }
    payload.attributes.push(obj);
  }
  if (isLoadMore && data) {
    payload.pageNumber = data.pageNumber + 1 < data.totalPages ? data.pageNumber + 1 : payload.pageNumber;
  }
  payload.isDateRange = true;
  delete payload.hub;
  delete payload.hubName;
  delete payload.paymentType;
  delete payload.vehicle;
  delete payload.addressStatus;
  delete payload.reset;
  delete payload.deliveryArea;
  delete payload.deliveryStatus;
  delete payload.deliveryExecutionStatus;
  delete payload.status;
  delete payload.deliverySlotTime;
  delete payload.driverId;
  delete payload.deliveryZone;
  delete payload.activity;
  return payload;
}



export const createPayloadForExcelExport = (payload: any, data?: any, countryCode?: any) => {
  payload.attributes = [];
  if (countryCode) {
    payload.countryISOCode = countryCode
  }
  if (payload.dateRange) {
    if (payload.dateRange && payload.dateRange.value) {
      payload.isDateRange = true;
      payload.dateRange = {
        startTime: moment(payload.dateRange.value.startDate, AppConstants.DATE_FORMAT).hours(0).minutes(0).seconds(0).format(AppConstants.DATE_FORMAT_BACKEND),
        endTime: moment(payload.dateRange.value.endDate, AppConstants.DATE_FORMAT).hours(23).minutes(59).seconds(59).format(AppConstants.DATE_FORMAT_BACKEND)
      };
    }
  }
  payload.isDateRange = true;
  payload.pageNumber = 0;
  delete payload.hub;
  delete payload.hubName;
  delete payload.paymentType;
  delete payload.reset;
  delete payload.deliveryArea;
  delete payload.deliveryStatus;
  delete payload.deliverySlotTime;
  delete payload.driver;
  return payload;
};

export const getCratesFromProducts = (items: any) => {
  let crates: any = [];
  items && items.forEach((item: any) => {
    if (item.substitutedProducts && item.substitutedProducts.length) {
      item.substitutedProducts && item.substitutedProducts.filter(({ shippedQty }: any) => { return +shippedQty > 0 }).forEach((element: any) => {
        if (element.associatedCrates) {
          let productCrates = element.associatedCrates;
          productCrates.forEach((crate: any) => {
            const crateValue = getValueFromArray(crates, "crateId", crate, "crateId");
            if (crateValue.length) {
              const crateIndex = crates.findIndex((value: any) => value.crateId === crate.crateId);
              const newCrate = { ...crate, quantity: parseInt(crates[crateIndex].quantity) + 1 };
              crates.splice(crateIndex, 1, newCrate);
            } else {
              const newCrate = { ...crate, quantity: 1 };
              crates.push(newCrate);
            }
          })
        }
      });
    } else {
      if (item.shippedQty > 0 && item.associatedCrates) {
        let productCrates = item.associatedCrates;
        productCrates.forEach((crate: any) => {
          const crateValue = getValueFromArray(crates, "crateId", crate, "crateId");
          if (crateValue.length) {
            const crateIndex = crates.findIndex((value: any) => value.crateId === crate.crateId);
            const newCrate = { ...crate, quantity: parseInt(crates[crateIndex].quantity) + 1 };
            crates.splice(crateIndex, 1, newCrate);
          } else {
            const newCrate = { ...crate, quantity: 1 };
            crates.push(newCrate);
          }
        })
      }
    }
  });
  return crates;
}

export const createCratesInfoPayload = (payload: any) => {
  if (payload) {
    let consignmentCode;
    let storeCode;
    if (payload.consignmentCode) {
      consignmentCode = payload.consignmentCode;
    }
    if (payload.posInformation) {
      storeCode = payload.posInformation.store
    };
    var consignmentCodePosCodeMap = {
      consignmentCodePosCodeMap: {
        [consignmentCode]: storeCode
      }
    }
    return consignmentCodePosCodeMap;
  }
}

export const createPayloadToFetchDriverList = (countryCode: any, pos: any) => {
  const payload = {
    hubCode: pos && pos.store,
    countryCode: countryCode
  }
  return payload;
}

export const createPayloadToAssignDriver = (selectedItem: any, consignment: any) => {
  const payload = {
    consignmentCode: consignment.consignmentCode,
    driverId: selectedItem.driverId
  }
  return payload;
}

export const rejectionDataTransform = (response: any) => {
  const transformedArray: any[] = [];
  response &&
    response.forEach((product: ProductsDataTransform) => {
      const { sku, entryNumber, rejectedQty, name, unitPrice } = product;
      let showReason = false;
      let qty = product.quantity;
      let isSubstitution =
        product.hasOwnProperty("substitutedProducts") 

      let isScalable = isSubstitution
        ? product?.substitutedProducts?.length && product.substitutedProducts[0].uom ?
          product.substitutedProducts[0].uom?.toLowerCase() === "grams"
          :product.uom?.toLowerCase() === "grams"
        : product.uom?.toLowerCase() === "grams";

      let totalQty = `${qty} ${
        product.uom && product.uom === "Grams" ? displayUnitGrams(product.uom) : ""
      }`;

      const rejectedItems = isSubstitution
        ? (product &&
            product.substitutedProducts &&
            product.substitutedProducts.length &&
            +product.substitutedProducts[0].rejectedQty) ||
          0
        : +product.rejectedQty || 0;

      const initialQty = isScalable
        ? rejectedItems > 0
          ? 1
          : 0
        : rejectedItems;

      let shipedQty = product.substitutedProducts &&
        product.substitutedProducts.length
          ? product.substitutedProducts[0].shippedQty
          : product.shippedQty;
  

      let shipedQtyDisplay =
        ((product.substitutedProducts &&
            product.substitutedProducts.length &&
            Number(product.substitutedProducts[0].shippedQty)===0)) ||
        (Number(product.shippedQty) === 0)
          ? 0
          : isScalable
          ? 1
          : product.substitutedProducts && product.substitutedProducts.length
          ? product.substitutedProducts[0].shippedQty
          : product.shippedQty
          ? product.shippedQty
          : 0;
    
      let newObj: any = [];
      let selectRejected = [];
      if(isScalable){
        for (let i = 0; i <= shipedQtyDisplay; i++) {
          newObj = transformForScalable(shipedQty, shipedQtyDisplay);
        }
      }
      else{
        for (let i = 0; i <= shipedQty; i++) {
            newObj = transformIntoObjArrays(i, shipedQty);
        }
      }
      
      if (initialQty === 0) {
        selectRejected = transformIntoObj(initialQty);
      } else {
        selectRejected = transformIntoObj(initialQty);
      }
    
      let deliveryReason = [];
      if (product.reason) {
        deliveryReason.push({
          name: product.reason.reasonDescription,
          value: product.reason.reasonCode,
        });
      } else {
        if (product.substitutedProducts) {
          product.substitutedProducts.forEach((subProd: any) => {
            if (subProd.reason) {
              deliveryReason.push({
                name: subProd.reason.reasonDescription,
                value: subProd.reason.reasonCode,
              });
            }
          });
        }
      }
      const uom = isSubstitution
      ? product?.substitutedProducts?.length && product.substitutedProducts[0]?.uom ?
        product.substitutedProducts[0].uom?.toLowerCase() === "grams" ? displayUnitGrams(product.substitutedProducts[0].uom) : "" 
        : product.uom?.toLowerCase() === "grams" ? displayUnitGrams(product.uom) : ""
      : product.uom?.toLowerCase() === "grams" ? displayUnitGrams(product.uom) : ""
  
      //Logic to showReason only when the rejectedQty is not zero
      if (rejectedQty !== "0" && !isUndefined(rejectedQty)) {
        showReason = true;
      }
      transformedArray.push({
        id: sku,
        name: name,
        entryNumber: entryNumber,
        shippedQty: shipedQty || 0,
        rejectedQty: rejectedQty || 0,
        unitPrice: unitPrice,
        totalQty: totalQty,
        rejectedQtyList: newObj,
        selectedRejectedQty: selectRejected,
        itemDeliveryReason: deliveryReason,
        substitutedProducts: product.substitutedProducts,
        showReason: showReason,
        error: "",
        uom: uom
      });
    });
  return [...transformedArray];
};


export const getDropdownsData = (payload: any, codeKey?: string, valueKey?: string) => {
  return payload.length ? payload.map((item: any) => {
    return {
      code: codeKey ? item[codeKey] : item.id,
      value: valueKey ? item[valueKey] : item.name
    }
  }) : [];
}

export const getAddressInfo = (addressObj: Address) => {
  return addressObj ? `${addressObj.building ? addressObj.building + ', ' : ""}${addressObj.apartment ? addressObj.apartment + ', ' : ""}${addressObj.zoneNumber ? addressObj.zoneNumber + ', ' : ""}${addressObj.makani ? addressObj.makani + ', ' : ""}${addressObj.streetNumber ? addressObj.streetNumber + ', ' : ""}${addressObj.streetName ? addressObj.streetName + ', ' : ""}${addressObj.area ? addressObj.area + ', ' : ""}${addressObj.landmark ? addressObj.landmark + ', ' : ""}${addressObj.zoneNumber ? addressObj.zoneNumber : ""}
  ${addressObj.city ? addressObj.city + ', ' : ""}${addressObj.postalCode ? addressObj.postalCode + ', ' : ""}${addressObj.countryIsoCode ? addressObj.countryIsoCode : ""}` : "";
}

export const getLatLong = (addressObj: Address) => {
  return addressObj ? `${addressObj.latitude ? parseFloat(addressObj.latitude).toFixed(5) + ', ' : ""}${addressObj.longitude ? parseFloat(addressObj.longitude).toFixed(5) : ""}` : "";
}

export const getWaitTimeForDriver = (checkinTime: any) => {
  const d = new Date();
  let checkTimeString = checkinTime && checkinTime.split('.')[0];
  const d2 = d.toISOString();
  const slot = moment.utc(d2).format(AppConstants.SLOT_FORMAT_BACKEND);
  let waitTime = calculateTimeBetweenSlots(checkTimeString && checkTimeString.split('T')[1], slot);
  return waitTime;
}

export const createExpressOrderReplanningPayload = (params: any) => {
  if (params.selection === "DELIVERED") {
    let payload: any = {
      consignmentCodes: [params.id]
    };
    payload.deliveryStatus = params.selection;
    payload.comments = params.commentsValue;
    return payload;
  } else {
    let payload: any = {
      consignmentCodes: [params.id]
    };
    payload.deliveryStatus = params.selection;
    payload.reason = params.reason ? {
      reasonCode: params.reason.reasonCode,
      reasonDescription: params.reason.reasonDescription,
      deliveryStatus: params.reason.deliveryStatus,
      comment: params.commentsValue
    } : {};
    return payload;
  }
}

export const createPayloadForReasons = (country: any, rejectionType: any) => {
  let deliveryType = '';
  if (rejectionType === EXPRESS_DELIVERY_TYPE.ON_HOLD) {
    deliveryType = AppConstants.EXPRESS_CONSTANTS.DELIVERY_TYPE.ON_HOLD
  } else if (rejectionType === EXPRESS_DELIVERY_TYPE.UNDELIVERED) {
    deliveryType = AppConstants.EXPRESS_CONSTANTS.DELIVERY_TYPE.DISPATCHER
  } else {
    deliveryType = AppConstants.EXPRESS_CONSTANTS.DELIVERY_TYPE.LINE_ITEM_REJECTION
  }
  const payload = {
    countryISOCode: country,
    type: deliveryType
  }
  return payload;
}

export const createPayloadForReplanDuringPartialRejection = (params: any, consignment: any) => {
  let payload: any = {};
  let prodLineItems: any = {};
  let lineItems: any = {};
  if (params.length > 0) {
    params.forEach((item: any) => {
      lineItems[item.entryNumber] = {
        rejectedQty: item.rejectedQty,
      }
    })
  }
  if (params.length > 0) {
    params.forEach((item: any) => {
      prodLineItems[item.entryNumber] = {
        entryNumber: item.entryNumber,
        rejectedQty: item.rejectedQty,
        sku: item.substitutedProducts?.length === 1
          ? item.substitutedProducts[0]?.sku
          : item.id,
        reason: {
          reasonCode: item.itemDeliveryReason?.length === 1 ? item.itemDeliveryReason[0].value : "",
          reasonDescription: item.itemDeliveryReason?.length === 1 ? item.itemDeliveryReason[0].name : ""
        }
      }
    });
  }
  payload.consignmentCodes = [consignment.consignmentCode];
  payload.lineItems = prodLineItems;
  payload.rejectedLineItems = lineItems;
  payload.deliveryStatus = AppConstants.EXPRESS_CONSTANTS.CONSIGNMENTS_DATA.DEFAULT_STATUS_SELECTION;
  payload.reason = params.reason ? {
    reasonCode: params.reason.reasonCode,
    reasonDescription: params.reason.reasonDescription,
    deliveryStatus: params.reason.deliveryStatus
  } : {}
  return payload;
}

export const createPayloadToMarkAddressUnverified = (consignment: any, address: any) => {
  const payload = {
    consignmentCode: consignment.consignmentCode,
    addressStatus: "UNVERIFIED"
  }
  return payload;
}

export const createPayloadToSubmitOrderComments = (consignment:any, reason:any, comments:string, desc:string)=>{
  const payload = {
    consignmentCode: consignment.consignmentCode,
    code: reason,
    description: desc,
    comment: comments
  }
  return payload;
}